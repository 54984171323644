import { bookingProps } from "src/interfaces";
import { HANDLE_LOAD_BOOKINGS, SET_BOOKINGS } from "./types";

export const fetchBookings = () => {
  return {
    type: HANDLE_LOAD_BOOKINGS,
  };
};

export const setBookings = (payload: bookingProps) => {
  return {
    type: SET_BOOKINGS,
    payload,
  };
};
