import { Box, Stack, useTheme } from "@mui/material";
import moment from "moment";
import airlines from "src/common/airlines.json";
import { Column, Stops } from "../../flightsList/components";
import { PrimaryButton } from "src/components/Buttons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { travellersPriceDetailHandler } from "../../flightsList/constants";
import LuggageInfo from "../luggageInfo";
import {
  cityAirportDictionaries,
  dictionariesProps,
  flightDataProps,
} from "src/interfaces";
import { addTax } from "src/helpers";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Airline from "../Airline";
import { getAirportName, getCanadaToCanada, getCarriers } from "../../helpers";
import { PriceCardDesktop, PriceCardMobile } from "../priceCards";

const Component = ({
  flightData,
  dictionaries,
  cityAirportDictionaries,
  dataIndex,
  oneway,
  onShowDetailClick = () => {},
  showDetail = false,
  onShowCancellationDescription = () => {},
  onBookClick = () => {},
}: {
  flightData: flightDataProps;
  dictionaries: dictionariesProps;
  cityAirportDictionaries: cityAirportDictionaries;
  dataIndex: number;
  oneway: boolean;
  onShowDetailClick: () => void;
  showDetail: boolean;
  onShowCancellationDescription: () => void;
  onBookClick: (payload: flightDataProps) => void;
}) => {
  const theme = useTheme();
  const itinerariesLength = flightData?.itineraries?.length;
  const carrierCodes = getCarriers(flightData?.itineraries) || [];
  return (
    <>
      {flightData?.itineraries?.map((itinerary, itineraryIndex) => {
        let duration: string = itinerary?.duration?.replace("PT", "");
        if (duration?.includes("H")) {
          duration = duration?.replace("H", " hr : ");
        } else {
          duration = "00 hr : " + duration;
        }
        if (duration?.includes("M")) {
          duration = duration?.replace("M", " min");
        }
        duration = !duration?.split(":")[1]?.trim()
          ? duration + " 00 min"
          : duration;

        const startSegment = itinerary?.segments[0];
        const lastSegment =
          itinerary?.segments[itinerary?.segments?.length - 1];
        const logo = airlines?.find(
          (ele) => ele?.id == startSegment?.carrierCode
        )?.logo;

        const departureDate = startSegment?.departure?.at;
        const arrivalDate = lastSegment?.arrival?.at;
        const stops = itinerary?.segments?.length - 1;

        const departureAirportData = getAirportName(
          cityAirportDictionaries,
          startSegment?.departure?.iataCode
        );

        const departureAirport = departureAirportData?.fullName;

        const arrivalAirportData = getAirportName(
          cityAirportDictionaries,
          lastSegment?.arrival?.iataCode
        );

        const arrivalAirport = arrivalAirportData?.fullName;

        let totalPrice = flightData?.price?.grandTotal;

        const isCanadaToCanada = getCanadaToCanada(
          itinerary?.segments,
          dictionaries?.locations
        );

        if (isCanadaToCanada) {
          totalPrice = addTax(flightData?.price)?.grandTotal as any;
        }

        let travelerPricingData: any =
          travellersPriceDetailHandler(flightData, isCanadaToCanada) || {};

        const showDetailBtnText = showDetail ? "Minimize" : "Show Details";
        const showDetailBtnIcon = showDetail ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        );

        const stopLabel = `${stops || "Non"} ${stops > 1 ? "Stops" : "Stop"}`;

        const seatsDetail = `${flightData?.numberOfBookableSeats} ${
          flightData?.numberOfBookableSeats > 1 ? "Seats" : "Seat"
        } Left`;

        const priceCardUI = (
          <PriceCardMobile
            seatsDetail={seatsDetail}
            onShowCancellationDescription={onShowCancellationDescription}
            onBookClick={onBookClick}
            flightData={flightData}
            isCanadaToCanada={isCanadaToCanada}
            travelerPricingData={travelerPricingData}
            totalPrice={totalPrice}
          />
        );

        const roundTripMobileUI = (
          <Column sx={{ width: "100%", px: 0.5 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box component="h5" sx={{ color: "black", mt: 0.5, mb: 0.5 }}>
                {startSegment?.departure?.iataCode}
              </Box>
              <Box
                sx={{
                  width: "15%",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 2,
                    backgroundColor: "gray",
                  }}
                />
                <Box
                  sx={{
                    mb: 0.7,
                  }}
                >
                  <Stops
                    stops={stops}
                    spacing={7}
                    sx={{ width: 7, minWidth: 7, height: 7, minHeight: 7 }}
                  />
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    mt: { md: 1 },
                    fontSize: 11,
                  }}
                >
                  {stopLabel}
                </Box>
              </Box>
              <Box component="h5" sx={{ color: "black", mt: 0.5, mb: 0.5 }}>
                {lastSegment?.arrival?.iataCode}
              </Box>
              <Box sx={{ fontSize: 12 }}>
                <Box sx={{ color: theme?.colors?.secondary?.main }}>
                  Departure
                </Box>
                <Box sx={{ color: "gray" }}>
                  {moment(departureDate as any).format("MM/DD/YYYY")},
                  {moment(departureDate as any).format("HH:mm")}
                </Box>
              </Box>
              <Box sx={{ fontSize: 12 }}>
                <Box sx={{ color: theme?.colors?.secondary?.main }}>
                  Arrival
                </Box>
                <Box sx={{ color: "gray" }}>
                  {moment(arrivalDate as any).format("MM/DD/YYYY")},
                  {moment(arrivalDate as any).format("HH:mm")}
                </Box>
              </Box>
              {flightData?.pricingOptions?.includedCheckedBagsOnly && (
                <LuggageInfo iconSx={{ fontSize: 13, mt: "auto", mb: 0.5 }} />
              )}
            </Stack>
          </Column>
        );

        const isMobile = document?.body?.clientWidth <= 991;

        const desktopTripUI = (
          <Stack
            sx={{
              position: "relative",
              ml: 2,
              display: { xs: "block ", md: "flex" },
              justifyContent: "space-between",
              width: "80%",
            }}
            direction="row"
          >
            <Column sx={{}}>
              <Box sx={{ color: "gray" }}>Departing from</Box>
              <Box component="h4" sx={{ color: "black", mt: 0.5, mb: 0.5 }}>
                {departureAirport}
              </Box>
              <Box
                sx={{
                  display: { md: "block", xs: "flex" },
                  alignItems: "center",
                  mt: "auto",
                }}
              >
                <Box sx={{ color: "gray" }}>
                  {moment(departureDate as any).format("MM/DD/YYYY")}
                </Box>
                <Box sx={{ color: "gray", ml: { md: 0, xs: 0.5 } }}>
                  {moment(departureDate as any).format("HH:mm")}
                </Box>
              </Box>
            </Column>
            <Column
              sx={{
                display: {
                  md: "block",
                  xs: "flex",
                },
                alignItems: "center",
                height: 70,
              }}
            >
              <Box
                sx={{
                  width: 2,
                  height: 30,
                  backgroundColor: "gray",
                  display: { md: "none", xs: "block" },
                }}
              />
              <Box
                sx={{
                  display: { md: "none", xs: "flex" },
                  alignItems: "center",
                  height: 30,
                  ".MuiStack-root": {
                    mt: 0,
                  },
                }}
              >
                <Stops
                  stops={stops > 0 ? 1 : 0}
                  sx={{
                    marginLeft: -0.8,
                  }}
                />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  mt: { md: 1 },
                  ml: 1,
                  display: { md: "none", xs: "block" },
                  color: {
                    md: "unset",
                    xs: theme?.colors?.secondary?.main,
                  },
                }}
              >
                {stopLabel}
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  mb: { md: 2 },
                  ml: { md: 0, xs: 1 },
                  display: { md: "block", xs: "flex" },
                  alignItems: "center",
                }}
              >
                <Box
                  component="h3"
                  sx={{
                    my: { md: 1, xs: "auto" },
                    color: {
                      md: "unset",
                      xs: theme?.colors?.alpha?.gray[100],
                    },
                    fontWeight: { md: "bold", xs: "normal" },
                  }}
                >
                  {duration}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: 2,
                  backgroundColor: "gray",
                  display: { md: "block", xs: "none" },
                }}
              />
              <Box sx={{ display: { md: "block", xs: "none" } }}>
                <Stops stops={stops} />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  mt: { md: 1 },
                  display: { md: "block", xs: "none" },
                }}
              >
                {stopLabel}
              </Box>
            </Column>
            <Column sx={{}}>
              <Box sx={{ color: "gray" }}>Arriving at</Box>
              <Box component="h4" sx={{ color: "black", mt: 0.5, mb: 0.5 }}>
                {arrivalAirport}
              </Box>
              <Box
                sx={{
                  display: { md: "block", xs: "flex" },
                  alignItems: "center",
                  mt: "auto",
                }}
              >
                <Box sx={{ color: "gray" }}>
                  {moment(arrivalDate as any).format("MM/DD/YYYY")}
                </Box>
                <Box sx={{ color: "gray", ml: { md: 0, xs: 0.5 } }}>
                  {moment(arrivalDate as any).format("HH:mm")}
                </Box>
                {flightData?.pricingOptions?.includedCheckedBagsOnly &&
                  isMobile && (
                    <LuggageInfo iconSx={{ ml: "auto", mr: 1, mt: -0.5 }} />
                  )}
              </Box>
            </Column>
            <Column
              sx={{
                width: itinerariesLength === 1 ? "24%" : "15%",
                mt: "auto",
                marginBottom: itinerariesLength === 1 ? -3 : 0,
                display: { md: "flex", xs: "none" },
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {flightData?.pricingOptions?.includedCheckedBagsOnly && (
                <LuggageInfo />
              )}
              {itinerariesLength === 1 && (
                <PrimaryButton
                  onClick={onShowDetailClick}
                  sx={{
                    textTransform: "capitalize",
                    ml: 0.5,
                    px: 0.7,
                    py: 0.7,
                    backgroundColor: theme?.colors?.error.main,
                    fontSize: 12,
                    ":hover": {
                      backgroundColor: {
                        md: theme?.colors?.error.light,
                        xs: theme?.colors?.alpha?.white[0],
                      },
                    },
                  }}
                >
                  {showDetailBtnText}
                  {showDetailBtnIcon}
                </PrimaryButton>
              )}
            </Column>
          </Stack>
        );

        return (
          <Box
            key={itineraryIndex}
            sx={{
              borderBottom: {
                xs:
                  itinerariesLength > 1 &&
                  itineraryIndex != itinerariesLength - 1
                    ? `1px dashed ${theme?.colors?.alpha?.gray[50]}`
                    : "unset",
                md: "unset",
              },
              my: {
                xs:
                  itinerariesLength > 1 &&
                  itineraryIndex != itinerariesLength - 1
                    ? 1
                    : "unset",
                md: "unset",
              },
            }}
          >
            {/* carriers name start */}
            {itineraryIndex === 0 && (
              <Box
                sx={{
                  position: {
                    md: "absolute",
                    xs: "relative",
                  },
                  left: 0,
                  width: { md: "81%", xs: "100%" },
                  p: 1,
                  px: 1.5,
                  backgroundColor: theme?.colors?.alpha?.secondary[10],
                  borderLeft: `2px solid ${theme?.colors?.error?.main}`,
                }}
              >
                <ArrowRightIcon
                  color="error"
                  sx={{ position: "absolute", left: -10, top: "18%" }}
                />
                {carrierCodes
                  ?.map((item) => dictionaries?.carriers[item])
                  ?.join(", ")}
              </Box>
            )}
            {/* carriers name end */}
            <Stack
              direction="row"
              key={itineraryIndex}
              sx={{
                alignItems: "center",
              }}
            >
              <Airline
                logo={logo}
                iconSx={{
                  width: { md: 50, xs: !oneway ? 20 : 50 },
                  ml: { xs: 1, md: 0 },
                }}
              />
              {!oneway && isMobile ? roundTripMobileUI : desktopTripUI}
              <PriceCardDesktop
                seatsDetail={seatsDetail}
                itineraryIndex={itineraryIndex}
                onBookClick={onBookClick}
                flightData={flightData}
                isCanadaToCanada={isCanadaToCanada}
                travelerPricingData={travelerPricingData}
                totalPrice={totalPrice}
              />
            </Stack>
            {(itineraryIndex === 0 && oneway && !showDetail) ||
            (!oneway && itineraryIndex === 1 && !showDetail)
              ? priceCardUI
              : null}
            <Box
              sx={{
                width: "80%",
                display: { md: oneway ? "none" : "flex", xs: "flex" },
                justifyContent: "flex-end",
                mt: { md: 1, xs: 0.5 },
                mb: { md: 1, xs: 0 },
                ml: { xs: "auto", md: "unset" },
              }}
            >
              {itinerariesLength - 1 === itineraryIndex && (
                <PrimaryButton
                  onClick={onShowDetailClick}
                  variant="text"
                  sx={{
                    textTransform: "capitalize",
                    px: 1,
                    py: 0.7,
                    backgroundColor: {
                      md: theme?.colors?.error.main,
                      xs: theme?.colors?.alpha?.white[0],
                    },
                    color: {
                      xs: theme?.colors?.error.main,
                      md: theme?.colors?.alpha?.white[100],
                    },
                    fontSize: 14,
                    ":hover": {
                      backgroundColor: {
                        md: theme?.colors?.error.light,
                        xs: theme?.colors?.alpha?.white[0],
                      },
                    },
                  }}
                >
                  {showDetailBtnText}
                  {showDetailBtnIcon}
                </PrimaryButton>
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default Component;
