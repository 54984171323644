import { Box, Stack, useTheme } from "@mui/material";
import moment from "moment";
import airlines from "src/common/airlines.json";
import { Column } from "../../flightsList/components";
import { travellersPriceDetailHandler } from "../../flightsList/constants";
import FlightIcon from "@mui/icons-material/Flight";
import { timeLabel, timeDifference } from "src/helpers";
import LuggageInfo from "../../components/luggageInfo";
import {
  cityAirportDictionaries,
  dictionariesProps,
  flightDataProps,
} from "src/interfaces";
import PriceDescription from "../../components/priceDescription";
import Airline from "../../components/Airline";
import { getAirportName, getCanadaToCanada } from "../../helpers";
import LayoverCard from "../../components/layoverCard";

const Component = ({
  flightData,
  dictionaries,
  cityAirportDictionaries,
  travelClass,
  oneway,
}: {
  flightData: flightDataProps;
  dictionaries: dictionariesProps;
  cityAirportDictionaries: cityAirportDictionaries;
  travelClass?: string;
  oneway?: boolean;
}) => {
  const theme = useTheme();
  const itinerariesLength = flightData?.itineraries?.length;

  return (
    <>
      {flightData?.itineraries?.map((itinerary, itineraryIndex) => {
        let initialArrival: any = "";
        let layover = 0;
        let layoverTime = "";

        const isCanadaToCanada = getCanadaToCanada(
          itinerary?.segments,
          dictionaries?.locations
        );

        let travelerPricingData: any =
          travellersPriceDetailHandler(flightData, isCanadaToCanada) || {};

        return (
          <Box
            sx={{
              px: { md: 0, xs: 1 },
            }}
          >
            {itinerary?.segments?.map((segment, segmentIndex) => {
              let luggageInfo = { quantity: 0, weight: 0, weightUnit: "" };
              if (flightData?.pricingOptions?.includedCheckedBagsOnly) {
                luggageInfo =
                  flightData?.travelerPricings[0]?.fareDetailsBySegment?.find(
                    (ele) => ele?.segmentId == segment?.id
                  )?.includedCheckedBags;
              }
              if (itinerary?.segments?.length > 1 && segmentIndex >= 1) {
                layover = 0;
                layoverTime = "";
                let nextDeparture = moment(segment["departure"]?.at);
                const diff = timeDifference(
                  nextDeparture,
                  initialArrival,
                  "difference",
                  false,
                  true
                );

                layover += diff as number;
                layoverTime = timeLabel(layover, null, "hm");
                initialArrival = moment(segment["arrival"]?.at);
              } else {
                initialArrival = moment(segment["arrival"]?.at);
              }

              let duration: string = segment?.duration?.replace("PT", "");
              if (duration?.includes("H")) {
                duration = duration?.replace("H", " hr : ");
              } else {
                duration = "00 hr : " + duration;
              }
              if (duration?.includes("M")) {
                duration = duration?.replace("M", " min");
              }
              duration = !duration?.split(":")[1]?.trim()
                ? duration + " 00 min"
                : duration;
              const logo = airlines?.find(
                (ele) => ele?.id == segment?.carrierCode
              )?.logo;

              const departureAirport = getAirportName(
                cityAirportDictionaries,
                segment?.departure?.iataCode
              )?.fullName;

              const arrivalAirport = getAirportName(
                cityAirportDictionaries,
                segment?.arrival?.iataCode
              )?.fullName;

              const departureDate = segment?.departure?.at;
              const arrivalDate = segment?.arrival?.at;
              return (
                <Box sx={{ px: 1 }}>
                  {segmentIndex === 0 && (
                    <Stack
                      component="h3"
                      spacing={1.5}
                      sx={{
                        alignItems: "center",
                        ml: { md: 1 },
                        pb: 0.5,
                      }}
                      direction="row"
                    >
                      <FlightIcon
                        color="secondary"
                        fontSize="medium"
                        sx={{
                          transform:
                            itineraryIndex === 0
                              ? "rotate(90deg)"
                              : "rotate(-90deg)",
                        }}
                      />
                      <Box>
                        {itineraryIndex === 0 ? "Outbound" : "Inbound"} Trip
                      </Box>
                      {flightData?.pricingOptions?.includedCheckedBagsOnly && (
                        <Box
                          sx={{
                            ml: "auto !important",
                            mr: "0.5rem !important",
                          }}
                        >
                          <LuggageInfo
                            quantity={luggageInfo?.quantity || 1}
                            weight={luggageInfo?.weight}
                            unit={luggageInfo?.weightUnit}
                          />
                        </Box>
                      )}
                    </Stack>
                  )}
                  <Box
                    key={`${itineraryIndex}-${segmentIndex}`}
                    sx={{
                      pl: { md: 5, xs: 0 },
                      my: {
                        xs:
                          itinerariesLength > 1 &&
                          itineraryIndex != itinerariesLength - 1
                            ? 1
                            : "unset",
                        md: "unset",
                      },
                    }}
                  >
                    {layoverTime && (
                      <LayoverCard
                        layover={layover}
                        layoverAirport={departureAirport}
                        layoverTime={layoverTime}
                        containerSx={{
                          backgroundColor: "#d0eeff",
                          borderRadius: 1,
                          px: 1,
                          py: 0.5,
                          my: 2,
                          alignItems: "center",
                          width: "auto",
                        }}
                      />
                    )}
                    <Stack
                      direction="row"
                      key={itineraryIndex}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Stack
                          sx={{
                            position: "relative",
                            justifyContent: "space-between",
                            alignItems: { md: "center" },
                            width: "100%",
                          }}
                          direction={{ md: "row", xs: "column" }}
                        >
                          <Column
                            sx={{
                              width: { md: "50%" },
                              maxWidth: { md: "50%" },
                            }}
                          >
                            <Box sx={{ color: "gray" }}>Departing from</Box>
                            <Box
                              component="h4"
                              sx={{ color: "black", mt: 0.5, mb: 0.5 }}
                            >
                              {departureAirport}
                            </Box>
                            <Stack
                              direction={{ xs: "row", md: "column" }}
                              sx={{
                                display: { md: "block", xs: "flex" },
                                alignItems: "center",
                              }}
                            >
                              <Box sx={{ color: "gray" }}>
                                {moment(departureDate as any).format(
                                  "MM/DD/YYYY"
                                )}
                              </Box>
                              <Box
                                sx={{ color: "gray", ml: { md: 0, xs: 0.5 } }}
                              >
                                {moment(departureDate as any).format("HH:mm")}
                              </Box>
                            </Stack>
                          </Column>
                          <Column
                            sx={{
                              width: { md: "50%" },
                              maxWidth: { md: "50%" },
                              mt: { md: 0, xs: 1 },
                            }}
                          >
                            <Box sx={{ color: "gray" }}>Arriving at</Box>
                            <Box
                              component="h4"
                              sx={{ color: "black", mt: 0.5, mb: 0.5 }}
                            >
                              {arrivalAirport}
                            </Box>
                            <Box
                              sx={{
                                display: { md: "block", xs: "flex" },
                                alignItems: "center",
                              }}
                            >
                              <Box sx={{ color: "gray" }}>
                                {moment(arrivalDate as any).format(
                                  "MM/DD/YYYY"
                                )}
                              </Box>
                              <Box
                                sx={{ color: "gray", ml: { md: 0, xs: 0.5 } }}
                              >
                                {moment(arrivalDate as any).format("HH:mm")}
                              </Box>
                            </Box>
                          </Column>
                        </Stack>
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            pr: { md: 0, xs: 2 },
                            mt: 0.6,
                          }}
                        >
                          <Airline
                            logo={logo}
                            iconSx={{ width: 15 }}
                            nameSx={{ ml: 1 }}
                            name={
                              <Box sx={{ fontSize: 14 }}>
                                <Box>{`${
                                  dictionaries?.carriers[
                                    segment?.carrierCode
                                  ] || ""
                                } ${segment?.number}`}</Box>
                                <Box>{travelClass}</Box>
                              </Box>
                            }
                          />
                          <Box sx={{ textAlign: "right" }}>
                            <Box>Flight Duration</Box>
                            <Box
                              sx={{
                                color: theme?.colors?.secondary?.light,
                                fontWeight: "bold",
                              }}
                            >
                              {duration}
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              );
            })}
            {itinerariesLength - 1 === itineraryIndex && (
              <Column
                sx={{
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <Stack
                  component="h3"
                  spacing={1.5}
                  sx={{
                    alignItems: "center",
                    ml: 1,
                    pb: 0.5,
                    borderBottom: `1px solid ${theme?.colors?.alpha?.gray[30]}`,
                  }}
                  direction="row"
                >
                  <Box>Total Price:</Box>
                </Stack>
                <PriceDescription
                  travelerPricingData={travelerPricingData}
                  isCanadaToCanada={isCanadaToCanada}
                  priceData={flightData?.price}
                  containerSx={{
                    width: { md: "60%", xs: "100%" },
                    ml: "auto",
                    p: 0.5,
                    px: 1,
                    color: theme?.colors?.alpha?.black[100],
                  }}
                  lineSx={{
                    width: { md: "90%", xs: "100%" },
                    mx: "auto",
                    my: 0.5,
                    height: 2,
                    backgroundColor: theme?.colors?.alpha?.black[70],
                  }}
                />
              </Column>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default Component;
