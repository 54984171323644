import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_ACTIVE_TAB, SET_FILTER_MODAL, SET_SHOW_DETAILS } from "./types";

export const filterModal = (
  state: boolean = false,
  action: PayloadAction<boolean> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_FILTER_MODAL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const activeTab = (
  state: "price" | "time" | "price&time" = "price",
  action: PayloadAction<"price" | "time" | "price&time"> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_ACTIVE_TAB: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const showDetails = (
  state: number[] = [],
  action: PayloadAction<number[]> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_SHOW_DETAILS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
