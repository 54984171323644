import { Box, Container, Link } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="lg" sx={{}}>
      <Box sx={{}}>
        <div className="text-block">
          <h1>Terms &amp; Conditions</h1>
          <p>
            The following terms and conditions (“Terms and Conditions”) apply to
            all travel and travel related Services offered for sale by
            Millwoods, Inc. and/or its agents, employees, associates, affiliated
            companies, independent contractors, or subcontractors (hereinafter
            referred to as “Millwoods”, “we,” or “us”). Millwoods, Inc. is
            incorporated in state of California. Our telephone number is
            1-844-455-5444.
          </p>
          <p>
            The terms “Service” or “Services” as used in these Terms and
            Conditions encompass: travel planning and consultation, charter or
            other airfare, passage on cruises, charters, river boats, yachts,
            and other water vessels, ground transportation, concierge services,
            hotel rooms, resorts, camps, or other lodgings, tours, car rentals,
            leisure or adventure activities, equipment rentals, expeditions, and
            any other travel or travel-related products offered, sold,
            recommended, or provided by Millwoods. The term “Trip” is defined as
            any Service, or package of Services, offered. “Supplier” or “Vendor”
            refers to any provider of the Services we offer. “Itinerary” refers
            to the particularized schedule for a Trip. References herein to
            “Traveler,” “you,” or “your” shall apply to each and any of the
            following: a party participating in an offered Trip and/or the party
            who purchases, or attempts to purchase, a Trip for themselves and/or
            others.
          </p>
          <p>
            <strong>
              All bookings of Trips are also subject to the Terms and Conditions
              of the supplier of the Service incorporated in a Millwoods Trip.
            </strong>{" "}
            By placing an order with Millwoods, you agree to abide by all the
            Terms and Conditions of the applicable Suppliers without
            reservation, and to be bound by the limitations therein. If the
            Supplier’s Terms and Conditions are ever in conflict with the Terms
            and Conditions of Millwoods, Millwoods’s will control all issues
            relating to the liabilities and responsibilities of Millwoods.
          </p>
          <p>
            Please read these Terms and Conditions carefully, ask us any
            questions, and consult your attorney before you agree to be bound by
            them. Traveler acknowledges that they have taken note of these Terms
            and Conditions before making a booking and have accepted the same by
            clicking on the “I Agree” box on the bottom of the submission page.
            When Traveler is purchasing a Trip for someone besides themselves,
            clicking on the “I Agree” box verifies Traveler has informed all
            other Travelers in their group of these Terms and Conditions and
            accepts them on their behalf, warranting that the other Travelers in
            their group, after consideration and with an opportunity to consult
            legal counsel, also agree to be bound by these Terms and Conditions.
            Without this acceptance, the processing of an order or inquiry is
            not technically possible. Therefore, by placing an order with
            Millwoods, you, and all Travelers in your group (if applicable),
            agree to abide by these Terms and Conditions without reservation and
            to be bound by the limitations herein.
          </p>
          <h2>Table of Contents</h2>
          <Box
            component="ol"
            sx={{ a: { color: "blue", textDecoration: "none" } }}
          >
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("eligibility")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Eligibility
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("modification-of-our-terms-and-conditions")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Modification of Our Terms and Conditions
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("payments")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Payments
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("prices")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Prices
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("cancellations-and-alterations-policies")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Cancellations and Alterations Policies
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("best-price-guarantee")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Best Price Guarantee
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("issuing-travel-documents")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Issuing Travel Documents
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("insurance")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Insurance
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById(
                      "passports-visas-health-requirements-and-travel-risks"
                    )
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Passports, Visas, Health Requirements, and Travel Risks
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById(
                      "accommodations-(hotels)-&amp;-car-rentals"
                    )
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Accommodations (Hotels) &amp; Car Rentals
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("notices")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Notices
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("seller-of-travel-disclosures")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Seller of Travel Disclosures
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("local-customs-and-laws")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Local Customs and Laws
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("legal-compliance-and-proper-conduct")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Legal Compliance and Proper Conduct
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("marketing-materials")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Marketing Materials
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("limitation-of-liability")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Limitation of Liability
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("disclaimer-of-warranties")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Disclaimer of Warranties
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("indemnification-and-release")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Indemnification and Release
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("force-majeure")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Force Majeure
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("special-rules-regarding-air-transport")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Special Rules regarding Air Transport
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("representations-and-warranties")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Representations and Warranties
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById(
                      "#medical-and-physical-conditions;-medical-emergencies"
                    )
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Medical and Physical Conditions; Medical Emergencies
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById(
                      "#disputes:-governing-law-jurisdiction-etc."
                    )
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Disputes: Governing Law, Jurisdiction, etc.
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById(
                      "#attorney’s-fees-costs-and-expenses-of-suit"
                    )
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Attorney’s Fees, Costs, and Expenses of Suit
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("assignment")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Assignment
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("severability-and-survivability")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Severability and Survivability
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    ?.getElementById("entire-agreement-waiver-etc.")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Entire Agreement, Waiver, Etc.
              </Link>
            </li>
          </Box>
          <ol>
            {" "}
            <li id="eligibility">
              <strong>Eligibility -</strong>{" "}
              <span>
                The Services offered by Millwoods are available for purchase by
                residents of the United States while in the United States, its
                territories, possessions, and protectorates, who have all the
                requisite power and authority to enter into and perform the
                obligations under these Terms and Conditions. Travelers must be
                over the age of 18 to purchase a Millwoods Trip.
              </span>
            </li>
            <li id="modification-of-our-terms-and-conditions">
              <strong>Modification of Our Terms and Conditions -</strong>{" "}
              <span>
                Our Terms and Conditions may be amended or modified by us at any
                time, without notice, on the understanding that such changes
                will not apply to Trips booked prior to the amendment or
                modification. It is therefore essential that you consult and
                accept our Terms and Conditions at the time of making a booking,
                particularly in order to determine which provisions are in
                operation at that time in case they have changed since the last
                time you placed an order with Millwoods or reviewed our Terms
                and Conditions.
              </span>
            </li>
            <li id="payments">
              <strong>Payments -</strong>{" "}
              <span>
                I understand if I have an unpaid balance to Millwoods Inc and do
                not make satisfactory payment arrangements, my account may be
                placed with an external collection agency. I will be responsible
                for reimbursement of any fees from the collection agency,
                including all costs and expenses incurred collecting my account,
                and possibly including reasonable attorney’s fees if so incurred
                during collection efforts. In order for Millwoods Inc or their
                designated external collection agency to service my account, and
                where not prohibited by applicable law, I agree that Millwoods
                Inc and the designated external collection agency are authorized
                to (i) contact me by telephone at the telephone number(s) I am
                providing, including wireless telephone numbers, which could
                result in charges to me, (ii) contact me by sending text
                messages (message and data rates may apply) or emails, using any
                email address I provide and (iii) methods of contact may include
                using pre-recorded/artificial voice message and/or use of an
                automatic dialing device, as applicable.
                <br /> Millwoods accepts payments by
              </span>
              <ol>
                <li>
                  International credit cards including Discover, American
                  Express, Visa and MasterCard.
                </li>
                <li>Wire transfer</li>
                <li>Cash deposited directly in our bank.</li>
              </ol>
            </li>
            <li id="prices">
              <strong>Prices -</strong>{" "}
              <span>
                <strong>Our prices are contractual tariffs -</strong>No claim
                relating to the price of a Trip will be considered once the
                reservation is effective. Unless otherwise stated in the
                description of the offer, the following will NOT be included as
                part of the advertised price:
              </span>
              <ul>
                <li>
                  items of a personal nature such as incidentals and laundry
                </li>
                <li>excess baggage charges</li>
                <li>port charges</li>
                <li>foreign government departure taxes</li>
                <li>costs of Passports or Visas</li>
                <li>inspection fees</li>
                <li>customs fees</li>
                <li>immigration and naturalization fees</li>
                <li>service fees</li>
                <li>single room supplement</li>
                <li>
                  travel insurance including health, accidents, lost baggage and
                  trip cancellation
                </li>
                <li>airport parking</li>
                <li>tips and gratuities</li>
                <li>
                  any other activities or items not explicitly advertised as
                  included in the price of a Trip in the description of the
                  offered Trip
                </li>
              </ul>
            </li>
            <li id="cancellations-and-alterations-policies">
              <strong>Cancellations and Alterations Policies -</strong>{" "}
              <span>
                Our Cancellations and Alterations Policies vary by Service
                and/or Trip sold. They are viewable in writing prior to purchase
                via email delivered to the purchaser. If those are unclear,
                please talk to one of our agents at the time of sale or at any
                other time prior to purchase.
              </span>
            </li>
            <li id="best-price-guarantee">
              <strong>Best Price Guarantee -</strong>{" "}
              <span>
                To make a Best Price Guarantee claim, customers should email{" "}
                <a href="mailto:service@millwoods.com">service@millwoods.com</a>{" "}
                within 24 hours of booking with Millwoods and include the
                competitor name, rate details and a screenshot of the other
                offer. If within that space of time after having purchased your
                flight, you find the same flight at a lower price and are able
                to document this for us, you are entitled to have an amount
                refunded which is equal to the difference between your total
                paid amount and the lower price which you have found. By the
                same flight, we mean a flight or several flights for the same
                number of persons and for the same routes, using the same
                airline(s), flight numbers, travel time, number of stops,
                outbound and inbound dates and times.
              </span>
              <p>
                <strong>CHANGES AFTER PURCHASE:</strong> All changes made to the
                booking after purchase are restricted and are strictly subject
                to the airline fare rules. Flight dates &amp; times are
                changeable subject to availability and upon payment of a change
                fee per person, plus any additional cost between the original
                total price paid and the lowest total price available for the
                new flight at the time the change is made. Any confirmation made
                beyond the time frame allowed may result in additional costs, of
                which you will be informed by e-mail. It is essential that you
                return the confirmation document, otherwise your initial
                reservation will stand.
              </p>
              <p>
                Any cancellation or alteration of your booking may result in
                costs that will be billed by our Suppliers, and our
                administrative costs may be added to these. In the event of an
                alteration, these costs will be billed to you. In the event of
                cancellation, these costs are deducted from the refund payable
                to you as per this agreement.
              </p>
              <p>
                The cancellation of your booking for whatever reason does not
                exempt you from paying all the sums that you owe to Millwoods.
                Any interrupted or shortened stay, or any Service not actually
                used by you, for whatever reason (in particular in the event
                that you fail to arrive in time to enjoy the Services) does not
                entitle you to a refund.
              </p>
              <p>
                As a general rule alterations, whatever their nature, are
                treated as a cancellation followed by a new booking, involving
                the relevant cancellation charges. These charges will be added
                to any costs charged by the Supplier, of which you will be
                informed before the alteration is made.
              </p>
              <p>
                <em>*California and Illinois Residents only:</em>
                <br />
                Upon cancellation of the transportation or travel services,
                where the Traveler is not at fault and has not canceled in
                violation of any terms and conditions previously clearly and
                conspicuously disclosed and agreed to by the Traveler, all sums
                paid to the seller of travel for services not provided will be
                promptly paid to the Traveler, unless the Traveler advises the
                seller of travel in writing, after cancellation. In California,
                this provision does not apply where the seller of travel has
                remitted the payment to another registered wholesale seller of
                travel or a carrier, without obtaining a refund, and where the
                wholesaler or provider defaults in providing the agreed-upon
                transportation or service. In this situation, the seller of
                travel must provide the Traveler with a written statement
                accompanied by bank records establishing the disbursement of the
                payment, and if disbursed to a wholesale seller of travel, proof
                of current registration of that wholesaler.
              </p>
              <ol>
                <li>
                  <p>
                    Cancellations and Alterations attributable to Millwoods{" "}
                    <br />
                    Particularly as a result of political or environmental
                    changes, rescheduling of air routes and times and other
                    circumstances outside our control, we have on occasions to
                    make changes to and cancel confirmed bookings. While we
                    always endeavor to avoid changes and cancellations, we must
                    reserve the right to do so. Most changes are minor.
                    Occasionally, we have to make a “significant change”. A
                    significant change is a change made before departure which,
                    taking account of the information you give us at the time of
                    booking, we can reasonably expect to have a major affect on
                    your confirmed Trip. Significant changes are likely to
                    include the following changes when made before departure
                    where applicable to your booking: a change of accommodation
                    to that of a lower standard for the whole or a major part of
                    the time you are away, a change of area of travel for the
                    whole or a major part of the time, a change of outward
                    departure time or overall length of time you are away of
                    twelve or more hours or a change of departure point to one
                    which is more inconvenient for you where your Trip includes
                    flights arranged by us.
                  </p>
                  <p>
                    If we have to make a significant change or cancel, we will
                    notify you as soon as possible. We will endeavor to offer
                    you alternative routing and/or dates and at least one
                    option, which is of a similar standard and cost. If there is
                    time to do so before departure, we will offer you the choice
                    of the following options:
                  </p>
                  <p>
                    Please note the above options are not available where any
                    change made is a minor one. If we have to make a significant
                    change or cancel we will pay you reasonable compensation
                    depending on the circumstances and when the significant
                    change or cancellation is notified to you subject to the
                    following exceptions:
                  </p>
                  <p>
                    Very rarely, we may be forced by "force majeure" to change
                    or terminate your Trip after departure but before the
                    scheduled end of your Trip. This is extremely unlikely but
                    if this situation does occur, we regret we will be unable to
                    make any refunds (unless we obtain any refunds from our
                    suppliers), pay you any compensation, or meet any costs or
                    expenses you incur as a result.
                  </p>
                  <ol>
                    <li>accepting the changed arrangements or</li>
                    <li>
                      purchasing an alternative Trip from us, of a similar
                      standard to that originally booked if available. We will
                      endeavor to offer You at least one alternative Trip of
                      equivalent standard for which You will not be asked to pay
                      any more than the price of the original Trip. If this Trip
                      is in fact cheaper than the original one, we will refund
                      the price difference. If You do not wish to accept the
                      Trip we specifically offer You, You may choose any of the
                      other arrangements we have available. You must pay the
                      applicable price of any such alternative arrangements.
                      This will mean Your paying more if they are more expensive
                      or receiving a refund if cheaper or
                    </li>
                    <li>
                      canceling or accepting the cancellation in which case you
                      will receive a full refund of all monies uou have paid to
                      us.
                    </li>
                    <li>
                      Compensation will not be payable and no liability beyond
                      offering the above mentioned choices can be accepted where
                      we are forced to make a change or cancel as a result of
                      unusual and unforeseeable circumstances beyond our
                      control, the consequences of which we could not have
                      avoided even with all due care.
                    </li>
                    <li>
                      No compensation will be payable and the above options will
                      not be available if we cancel as a result of your failure
                      to comply with any requirement of these booking conditions
                      entitling us to cancel (such as paying on time).
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Cancellations and Alterations attributable to You <br />
                    <strong>Important:</strong> All bookings of airline tickets,
                    &amp; vacation packages are non-refundable generally unless
                    specifically described otherwise. Any alteration, transfer,
                    or cancellation relating to airfare, or dynamic packages
                    (flight + hotel) which affect the booked flight(s), will
                    involve charges up to the price of the air tickets bought
                    including, without limitation, all fees, taxes, and charges.
                    All cancellations must be done over the phone only. We can
                    accept refund requests only if the following conditions have
                    been met:
                  </p>
                  <p>
                    All refund requests are processed in a set format. Once your
                    cancellation request has been submitted with our customer
                    care agent, you will get an email notification acknowledging
                    your request . This acknowledgement does not automatically
                    qualify you for a refund. Based upon the fare rule we work
                    with the airline or other involved suppliers to generate a
                    waiver and process the refund if fare rules allow.
                    Cancellation vary as per fare rules, Refunds may take up to
                    4-6 business weeks to process.
                  </p>
                  <ol>
                    <li>
                      Only if the fare rules provide for cancellation and
                      refunds;
                    </li>
                    <li>
                      It is not "no show" situation (most "no show" bookings are
                      in-eligible for any waiver from suppliers for refund
                      processing)
                    </li>
                    <li>
                      we are able to receive waivers from suppliers to process
                      the requested cancellation and refund
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li id="issuing-travel-documents">
              <strong>Issuing Travel Documents -</strong>{" "}
              <span>
                The method for issuing travel documents and/or tickets depends
                on the time available between the date of issue of tickets and
                your date of departure, and/or the type of Service. If a
                Traveler provides incorrect information, Millwoods does not
                assume any liability if the Trip is adversely affected or made
                impossible by the non-receipt of travel documents.
              </span>
              <p>
                Travel documents will only be sent to the purchasing Traveler
                who places the order and personally agrees to these Terms and
                Conditions.
              </p>
            </li>
            <li id="insurance">
              <strong>Insurance -</strong>{" "}
              <span>
                Millwoods strongly recommends that all Travelers purchase some
                form of Travel, Cancellation, Lost Baggage and/or Medical
                Emergency Insurance for all Trips. While we can refer you to an
                insurance company, Traveler acknowledges that it is Traveler’s
                responsibility to understand the limitations of their insurance
                coverage and purchase additional insurance as needed. It is the
                Traveler’s sole responsibility to research, evaluate and
                purchase appropriate coverage. Traveler agrees that Millwoods is
                not responsible for any uninsured losses.
              </span>
            </li>
            <li id="passports-visas-health-requirements-and-travel-risks">
              <strong>
                Passports, Visas, Health Requirements, and Travel Risks -
              </strong>{" "}
              <span>
                It is Traveler’s responsibility to verify they have all the
                necessary visas, passport, and vaccinations prior to travel. A
                full and valid passport is required for all persons traveling to
                any of the destinations outside the U.S. that we feature. You
                must obtain and have possession of a valid passport, all visas,
                permits and certificates, and vaccination certificates required
                for your entire Trip. Most out of international Trips (including
                India) require a passport valid until at least six (6) months
                beyond the scheduled end of your Itinerary. Non-U.S. citizens
                should contact the appropriate consular office for any
                requirements pertaining to their Trip.
              </span>
              <p>
                If you try to enter a country where visa is required, and you do
                not have the visa, it is possible that you will be imprisoned
                until there is an available flight to return you to your point
                of origin. India requires at least a Tourist Visa to visit.
              </p>
              <p>
                Some countries require you to be in possession of a return
                ticket or exit ticket and have sufficient funds, etc. Similarly,
                certain countries require that the Traveler produce evidence of
                insurance/repatriation coverage before it will issue a visa.
              </p>
              <p>
                You must carefully observe all applicable formalities and ensure
                that the surnames and forenames used for all passengers when
                making a booking and appearing in your travel documents (booking
                forms, travel tickets, vouchers, etc.), correspond exactly with
                those appearing on your passport, visas, etc.
              </p>
              <p>
                Further information on entry requirements can be obtained from
                the State Department, by phone (202) 647-5335 or access online
                at{" "}
                <a href="https://travel.state.gov/content/travel.html">
                  //travel.state.gov/travel{" "}
                </a>{" "}
                or directly from the destination country's website. Requirements
                for traveling to India change frequently, often with little
                advance notice, and changes may be poorly advertised and
                inconsistently enforced. Travelers are urged to check the
                website of the Indian Embassy in Washington, D.C. (
                <a href="https://www.indianembassy.org/">
                  https://www.indianembassy.org/
                </a>
                ) before any travel to India to review the most current
                information. We recommend that you check these before booking
                and also shortly before departure as requirements may change.
              </p>
              <p>
                Immunization requirements vary from country to country and even
                region to region. Up-to date information should be obtained from
                your local health department and consulate. You assume complete
                and full responsibility for, and hereby release Millwoods from,
                any duty of checking and verifying vaccination or other entry
                requirements of each destination, as well as all safety and
                security conditions of such destinations during the length of
                the proposed travel or extensions expected or unexpected. For
                State Department information about conditions abroad that may
                affect travel safety and security, go to
                <a href="https://travel.state.gov/content/travel/en/international-travel/before-you-go/your-health-abroad.html">
                  //travel.state.gov/travel/travel_1744.html
                </a>
                , or contact them by phone at (202) 647-5335. For foreign health
                requirements and dangers, contact the U.S. Centers for Disease
                Control (CDC) at (404) 332-4559, use their fax information
                service at (404) 332-4565, or go to{" "}
                <a href="https://wwwnc.cdc.gov/travel/">
                  //wwwnc.cdc.gov/travel/
                </a>
                .
              </p>
              <p>
                It is your responsibility to ensure that you hold the correct,
                valid documents for the countries you are visiting and have
                obtained the necessary vaccinations, clearance to travel, and
                hold the necessary confirmations for medications required as we
                cannot be held liable for any illness, delays, compensation,
                claims and costs resulting from your failure to meet these
                requirements.
              </p>
              <p>
                WE CANNOT ACCEPT RESPONSIBILITY IF YOU ARE REFUSED PASSAGE ON
                ANY AIRLINE, CRUISE, TRANSPORT OR ENTRY INTO ANY COUNTRY DUE TO
                THE FAILURE ON YOUR PART TO CARRY OR OBTAIN THE CORRECT
                DOCUMENTATION. IF FAILURE TO DO SO RESULTS IN FINES, SURCHARGES,
                CLAIMS, FINANCIAL DEMANDS OR OTHER FINANCIAL PENALTIES BEING
                IMPOSED ON US, YOU WILL BE RESPONSIBLE FOR INDEMNIFYING AND
                REIMBURSING US ACCORDINGLY.
              </p>
              <p>
                Although most travel to participating destinations is completed
                without incident, travel to certain areas may involve greater
                risk than others. You assume sole responsibility for your own
                safety at any destination traveled to. Millwoods does not
                guarantee your safety at any time, and assumes no responsibility
                for gathering and/or disseminating information for you relating
                to risks associated with your destinations. BY OFFERING OR
                FACILITATING TRAVEL TO CERTAIN DESTINATIONS, WE DO NOT REPRESENT
                OR WARRANT THAT TRAVEL TO SUCH POINTS IS ADVISABLE OR WITHOUT
                RISK, AND WE SHALL NOT BE LIABLE FOR DAMAGES OR LOSSES THAT MAY
                RESULT FROM TRAVEL TO SUCH DESTINATIONS.
              </p>
              <p>
                <b>PASSPORT / VISA REQUIREMENTS </b> <br />
                FOR DOMESTIC TRAVEL: A valid government photo I.D. must be
                presented by all travelers in order to board domestic flights.
                Minors traveling domestically with parents generally do not need
                a photo I.D. If traveling with children less than two (2) years
                old, a birth certificate may be needed to confirm the infant's
                age.{" "}
                <a href="https://www.tsa.gov/travel/security-screening/identification">
                  More Information
                </a>
                .
              </p>
              <p>
                FOR INTERNATIONAL TRAVEL: All travelers MUST be in possession of
                a valid government issued Passport or Identification. Travelers
                MUST also have the necessary documents, Visas, Transit visas,
                Schengen Visas and all other entry permits for all international
                ports of entry.
              </p>
              <p>
                In addition, your passport must be valid for 6 months after your
                return date when entering your destination. While sometimes we
                may be able to assist with visa and passport information, it is
                solely the responsibility of the passenger(s) to arrange for all
                documents needed to enter the country you are traveling to, or
                passing through in transit. Please note, a roundtrip or ongoing
                ticket may be required for certain international cities. If you
                are traveling one way, please verify with the airline or
                Consulate General to prevent any issues at time of boarding.
              </p>
              <ul className="inner-list">
                <li>
                  <a href="https://travel.state.gov/content/travel/en/passports.html/">
                    US Passport Holder Information
                  </a>
                </li>
                <li>
                  <a href="https://travel.gc.ca/travelling/documents/visas">
                    Canadian Passport Holder Information
                  </a>
                </li>
                <li>
                  <a href="https://visacentral.com/?login=401601">
                    Other Passport Holder
                  </a>
                </li>
              </ul>
            </li>
            <li id="accommodations-(hotels)-&amp;-car-rentals">
              <strong>Accommodations (Hotels) &amp; Car Rentals -</strong>{" "}
              <span>
                “Accommodations” are any lodgings in a dwelling or similar
                living quarters afforded to Travelers including, but not limited
                to, hotels, quarters in ships and charters, camp grounds,
                motels, and resorts. Millwoods provides the accommodations for
                its Trips through third-party Vendors and retains no ownership
                or management interest in those Accommodations. Millwoods does
                not guarantee the location or the amenities of the
                Accommodations nor the performance of the third-party Vendors.
                If any issues arise, please contact the owner/operators of the
                respective Accommodations directly.
              </span>
              <p>
                Prices of Accommodations are based on double occupancy unless
                described otherwise. If you prefer single Accommodations, some
                Accommodations require you to pay a single supplement fee which
                can vary depending on the Accommodation. Please contact us for
                specifics on the single supplement fees for each Trip.
              </p>
              <p>
                <b>For Hotel &amp; Car rental bookings</b>
                <br />
                The reservation holder must present a valid photo ID and credit
                card at check-in. The credit card is not only used to guarantee
                the reservation, it is required for any additional hotel
                specific service fees or incidental charges or fees that may be
                charged by the hotel at checkout. These charges may be mandatory
                (e.g., resort fees) or optional (parking, phone calls or minibar
                charges) and are not included in your per night price. The
                renter of the vehicle must be present to sign the rental
                agreement and provide their credit card at the time of pick up.
                The renter cannot provide a credit or debit card belonging to
                someone else for their use.
              </p>
            </li>
            <li id="notices">
              <strong>Notices -</strong>{" "}
              <span>
                Any notices required or permitted hereunder shall be given via
                email to :{" "}
                <a href="mailto:Hello@millwoods.com">Hello@millwoods.com</a>
              </span>
            </li>
            <li id="seller-of-travel-disclosures">
              <strong>Seller of Travel Disclosures -</strong>{" "}
              <span>
                California law requires certain sellers of travel to have a
                trust account or bond. Millwoods has a trust account with First
                Tech Credit Union.
              </span>
              <p>
                Millwoods is a participant in the California Travel Consumer
                Restitution Fund (TCRF).
              </p>
              <p>
                This transaction is covered by the TCRF if the seller of travel
                was registered and participating in the TCRF at the time of sale
                and the passenger is located in California at the time of
                payment. Eligible passengers may file a claim with TCRF if the
                passenger is owed a refund of more than $300 for transportation
                or travel Services which the seller of travel failed to forward
                to a proper provider or such money was not refunded to you when
                required. The maximum amount which may be paid by the TCRF to
                any one passenger is the total amount paid on behalf of the
                passenger to the seller of travel, not to exceed $15,000. A
                claim must be submitted to the TCRF within 12 months after the
                scheduled completion date of the travel. A claim must include
                sufficient documentation to prove your claim and a $35
                processing fee. Claimants must agree to waive their right to
                other civil remedies against a registered participating seller
                of travel for matters arising out of a sale for which you file a
                TCRF claim. You may request a claim form by writing to: Travel
                Consumer Restitution Corporation; P.O. Box 6001; Larkspur, CA
                94977-6001; or by visiting TCRC’s website at:{" "}
                <a href="https://www.tcrcinfo.org/">www.tcrcinfo.org</a>.
              </p>
              <p>
                If Traveler is purchasing from outside of California, this
                transaction is not covered by the California Travel Consumer
                Restitution Fund.
              </p>
            </li>
            <li id="local-customs-and-laws">
              <strong>Local Customs and Laws -</strong>{" "}
              <span>
                Some Traveler will be traveling to foreign countries, with
                different customs, standards, laws and risks than those
                Travelers are accustomed to. Traveler understands that he or she
                must be prepared to cope with the unexpected, with local customs
                and shortages, with the vagaries of weather, travel and mankind
                in general. As such, Traveler acknowledges and accepts the risks
                associated with travel in a foreign country and agrees to
                release and hold Millwoods harmless for any such problems
                experienced while participating in their Trip.
              </span>
              <p>
                All Travelers must obey the local laws and regulations of the
                countries they visit on their Trip. Millwoods is not liable or
                responsible for any damages, costs and/or added expenses
                incurred as a result of Traveler’s failure to obey any local,
                provincial or federal laws.
              </p>
            </li>
            <li id="legal-compliance-and-proper-conduct">
              <strong>Legal Compliance and Proper Conduct -</strong>{" "}
              <span>
                Traveler understands that their participation in a Trip may be
                terminated if Traveler is disciplined by any civil or criminal
                authorities, charter operator, or if Millwoods, in its sole
                discretion, determines that Traveler's conduct is incompatible
                with the interests, safety or welfare of other Travelers or
                Service providers, their employees, or independent contractors.
                In this event, Traveler is responsible for arranging and paying
                for substitute travel and Accommodations.
              </span>
            </li>
            <li id="marketing-materials">
              <strong>Marketing Materials -</strong>{" "}
              <span>
                Millwoods endeavors to illustrate the Services it offers using
                photographs or illustrations that provide a realistic
                representation of the Services offered. However, please note
                that photographs and illustrations appearing in descriptions are
                for illustrative purposes only. They are binding on Millwoods
                only to the extent that they illustrate the type or standard of
                such Services, and are not contractual nor are they to be
                construed as guarantees of the conditions of the places or
                Accommodations pictured at the time of your Trip.
              </span>
              <p>
                The travel information that we provide you on our site and in
                newsletters, brochures and the like about options for
                Accommodations, excursions, restaurants, bars/clubs, etc. comes
                from a variety of sources, including information published by
                the establishments themselves, by other travel guides, and by
                on-line research, and is believed to be accurate. Nonetheless,
                some of the information could not be independently verified and
                may be inaccurate and not up to date. You should not view the
                information as recommendations, and should do your own
                investigation to confirm that the business/site in question is
                still operational and suits your needs.
              </p>
              <p>
                Millwoods may occasionally use statements made by its Travelers
                and/or their photographs, images, or other likenesses, in
                various marketing materials, on our website, or at promotional
                events. Traveler fully consents to such use of Traveler’s
                statements and/or their photographs, images, or other
                likenesses, for marketing or promotional purposes without the
                payment of any compensation to Traveler and grants Millwoods a
                non-revocable license for said use.
              </p>
            </li>
            <li id="limitation-of-liability">
              <strong>Limitation of Liability -</strong>{" "}
              <span>
                IN NO EVENT SHALL MILLWOODS BE LIABLE FOR ANY CONSEQUENTIAL,
                INDIRECT, EXEMPLARY, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES OF
                ANY KIND, INCLUDING WITHOUT LIMITATION, DAMAGES FOR ANY LOSS OF
                OPPORTUNITY OR OTHER PECUNIARY LOSS, EVEN IF MILLWOODS HAS BEEN
                ADVISED OF THE POSSIBILITY OR PROBABILITY OF SUCH DAMAGES OR
                LOSSES, WHETHER SUCH LIABILITY IS BASED UPON CONTRACT, TORT,
                NEGLIGENCE OR OTHER LEGAL THEORY. IN NO EVENT SHALL MILLWOODS’S
                TOTAL AGGREGATE LIABILITY TO THE TRAVELER FOR CLAIMS ARISING
                UNDER THIS AGREEMENT EXCEED THE TOTAL AMOUNTS PAID BY THE
                TRAVELER TO MILLWOODS UNDER THIS AGREEMENT.
              </span>
              <p>
                MILLWOODS IS ACTING AS A MERE AGENT FOR ALL SUPPLIERS OF
                SERVICES AND ACCOMMODATIONS ADVERTISED AND/OR SOLD BY US. ALL
                SUPPLIERS OF SERVICES AND ACCOMMODATIONS ADVERTISED AND/OR SOLD
                BY MILLWOODS ARE THIRD PARTY VENDORS AND MILLWOODS RETAINS NO
                OWNERSHIP INTEREST, MANAGEMENT, OR CONTROL OF THOSE THIRD PARTY
                VENDORS. TO THE FULLEST EXTENT PERMITTED BY LAW, MILLWOODS DOES
                NOT ASSUME LIABILITY FOR ANY INJURY, DAMAGE, DEATH, LOSS,
                ACCIDENT OR DELAY DUE TO AN ACT OR OMISSION OF ANY THIRD PARTIES
                (INCLUDING THIRD PARTY VENDORS), GOVERNMENTAL AUTHORITY, OR ACTS
                ATTRIBUTABLE TO YOU YOURSELF, INCLUDING, WITHOUT LIMITATION,
                NEGLIGENT OR RECKLESS ACTS, EVEN IF MILLWOODS HAS BEEN ADVISED
                THAT SUCH DAMAGES WERE POSSIBLE OR PROBABLE.
              </p>
            </li>
            <li id="disclaimer-of-warranties">
              <strong>Disclaimer of Warranties -</strong>{" "}
              <span>
                UNLESS OTHERWISE STATED, ALL GOODS AND SERVICES OFFERED BY
                MILLWOODS ARE PROVIDED TO YOU ON AN "AS IS," "AS AVAILABLE"
                BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
                LAW, MILLWOODS DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF
                ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, SUITABILITY FOR A
                PARTICULAR PURPOSE, TITLE, UNINTERRUPTED SERVICE, AND ANY
                WARRANTIES ARISING OUT OF A COURSE OF PERFORMANCE, DEALING OR
                TRADE USAGE FOR ALL GOODS AND SERVICES SOLD BY/THROUGH
                MILLWOODS. Applicable law in your jurisdiction may not allow the
                exclusion of implied warranties, so the above exclusions may not
                apply to you.
              </span>
            </li>
            <li id="indemnification-and-release">
              <strong>Indemnification and Release -</strong>{" "}
              <span>
                Except as otherwise set forth herein, Traveler hereby releases
                Millwoods from any and all liability, loss, expense, damages, or
                claims arising out of or resulting from Traveler’s participation
                in a Trip, whether caused by the negligent, intentional, or
                reckless conduct of Traveler, a Service Provider, a provider of
                Accommodations, another third party, or otherwise.
              </span>
              <p>
                Traveler hereby also agrees to indemnify, defend and hold
                harmless Millwoods from and against any and all damages, losses,
                claims, liabilities, deficiencies, costs, fees (including
                reasonable attorneys' fees) and expenses, arising out of any
                claim brought against Millwoods regarding, resulting, or arising
                from Traveler’s participation in a Trip or Traveler’s
                performance of this Agreement.00
              </p>
            </li>
            <li id="force-majeure">
              <strong>Force Majeure -</strong>{" "}
              <span>
                Millwoods shall not be responsible for failure to perform any of
                its obligations under this Agreement during any period in which
                such performance is prevented or delayed due to Force Majeure.
                “Force Majeure” refers to any event beyond Millwoods’ reasonable
                control, including but not limited to severe weather, fire,
                flood, mudslides, earthquakes, war, labor disputes, strikes,
                epidemics, World Health Organization’s advisories and/or alerts,
                Center for Disease Control’s advisories and/or alerts, U.S.
                State Department’s advisories and/or alerts, any order of any
                local, provincial or federal government authority, interruption
                of power Services, terrorism or any other causes beyond the
                control of Millwoods or deemed by Millwoods to constitute a
                danger to the safety and well-being of Travelers. Millwoods
                reserves the right to cancel any Services described in a Trip
                Itinerary due to Force Majeure.
              </span>
            </li>
            <li id="special-rules-regarding-air-transport">
              <strong> AIR TRANSPORT</strong>{" "}
              <ul>
                <li>
                  <p>
                    <strong>General conditions governing air transport</strong>
                    <br />
                    Millwoods’s responsibilities in respect to air travel are
                    limited by the relevant airline’s conditions of carriage.
                    The airline fulfilling your contract for carriage may change
                    from the airline mentioned in our electronic brochure and
                    advertisements. Millwoods is not able to specify the type of
                    aircraft to be used by any airline. In addition, Millwoods
                    is not responsible for losses due to cancelled flights,
                    seats, or changed flight itineraries. Airlines retain the
                    right to adjust flight times and schedules at any time;
                    schedule changes can result in an itinerary that falls
                    outside of contractual agreements. You cannot cancel the
                    contract without penalty due to a change of airline,
                    aircraft type or destination. If an airline cancels or
                    delays a flight, you should work with the airline to ensure
                    you arrive at your destination on or ahead of time.
                    Millwoods will not provide any refund for Trips missed, in
                    part or full, due to missed, cancelled or delayed flights,
                    or other flight irregularities including, without
                    limitation, denied boarding whether or not you are
                    responsible for such denial.
                  </p>
                  <p>
                    Direct flights may be “non stop” or may involve one or more
                    stop-overs (in the latter case this means the same flight by
                    the airline, because the flight number remains the same).
                    The same applies to connecting flights that may be subject
                    to crew changes. When you reserve a scheduled or charter
                    flight involving a stop-over in a town, and the second
                    flight takes off from a different airport to the airport of
                    arrival, ensure that you have sufficient time for reaching
                    the second airport. The journey to the other airport is at
                    your own expense. MILLWOODS will not be able to reimburse
                    you for these costs, nor will it be liable if you miss the
                    second flight.
                  </p>
                  <p>
                    Millwoods will not bear any liability, particularly in the
                    event of timetable changes, delays, cancellations, etc.,
                    attributable to force majeure (labor dispute, strikes,
                    storms, wars, earthquakes, epidemics, etc.) or in the event
                    that, after the specified arrival time at the airport, it is
                    found that the customer is refused boarding for failure to
                    comply with the administrative or health formalities, or
                    failure to check-in.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Problems related to the issuance of e-tickets
                    </strong>
                    <br />
                    As of June 1st, 2008, the International Air Transport
                    Association (IATA) has imposed new rules with regard to the
                    issuing of air travel tickets. As of that date, travel
                    agencies and airlines have an obligation to only issue
                    travel tickets via electronic means (i.e. electronic ticket
                    or “e-ticket”).
                  </p>
                  <p>
                    Due to technical constraints to do with airline’s
                    restrictions in relation to certain requirements (infants
                    under the age of 2, inter-airline agreements, groups, etc.),
                    it may be impossible to issue an electronic ticket.
                    Therefore, though a flight may be shown as available, it
                    might prove impossible for us to honor your reservation.
                    This situation, which is outside our control, will not
                    result in liability on our part.
                  </p>
                  <p>
                    If we cannot issue you an e-ticket, we will contact you to
                    propose an alternative route solution. This could involve a
                    different tariff and/or additional costs for which you would
                    be responsible. In the event of the absence of an
                    alternative solution, your refusal to pay any tariff
                    difference, or if the issuance of tickets proves impossible,
                    we would be forced to cancel your reservation at no cost to
                    you. We will provide you with a full refund within 30 days
                    after determining that there is no alternative solution
                    possible.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Failure to check-in</strong>
                    <br />
                    Failure to check-in for a flight on the outward journey (on
                    a charter or scheduled flight) will automatically result in
                    cancellation of the return flight by the airline. We would
                    encourage you to contact us on the date of departure if you
                    wish us to keep the return flight open; this decision
                    remains at the discretion of the airline company.
                  </p>
                  <p>
                    Any interrupted or shortened journey, or any service that
                    you do not take up, will not entitle you to a refund. If you
                    have taken out insurance coverage, and in particular
                    insurance for the interruption of a stay, you must comply
                    with the procedures for cancellation appearing in your
                    insurance contract.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Flight connections</strong>
                    <br />
                    If any booked flight connecting with your outbound or
                    inbound flight is cancelled or delayed, the airlines reserve
                    the right to provide that transport by any other means
                    (coach/bus, train, etc.).
                  </p>
                  <p>
                    If you organize your own connecting transport with the
                    arrangements booked with Millwoods, we would advise that you
                    reserve flexible or refundable tickets in order to avoid the
                    risk of any financial loss. You are also advised not to make
                    any important appointments for the day following your return
                    date.
                  </p>
                  <p>
                    Millwoods cannot accept responsibility for the consequences
                    of delays (such as a cancelled scheduled flight) in the
                    context of connecting transport organized by you.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>The return</strong>
                    <br />
                    Whatever the type of flight, scheduled or charter, it is
                    essential that the return be re-confirmed locally with the
                    airline within 72 hours prior to the envisaged date of
                    departure. For package Trips, this formality is generally
                    performed by the local representative or agent. We would
                    draw your attention to the fact that this procedure is
                    compulsory and that, if you fail to do so, your seat cannot
                    be guaranteed by the airline, which has the right to
                    allocate your seat to someone else. In addition, this
                    procedure also gives you the opportunity to confirm the
                    times of your return flight which may have been altered in
                    the meantime. Millwoods cannot be held liable for any
                    negligence on your part in failing to re-confirm your return
                    flight.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Luggage</strong>
                    <br />
                    Millwoods assumes no liability for any loss or damage to
                    baggage or personal effects, whether in transit to or from a
                    Trip, or during a Trip. The airline is liable to you for the
                    baggage you entrust to it only for the compensation
                    contemplated in the international conventions and relevant
                    statutes. In the event of damage, late forwarding, theft or
                    loss of luggage, you should contact the your airline and
                    declare the damage, absence or loss of your personal effects
                    before leaving the airport, and then submit a declaration,
                    attaching the originals of the following documents: the
                    travel ticket, the baggage check-in slip, and the
                    declaration. It is recommended that you take out an
                    insurance policy covering the value of your items.
                  </p>
                  <p>
                    Additional and oversized baggage fees: Most airlines have
                    their own policy regarding luggage. We recommend that you
                    check with your airline ahead of time for any weight
                    restrictions and additional charges relating to checked
                    baggage. You will be responsible for paying to the airline
                    any additional charges for checked or overweight baggage,
                    including, but not limited to, golf bags and oversized
                    luggage. If you exceed the weight limit set by your airline,
                    and excess weight is permitted, you must pay a supplement
                    directly to the airline at the airport.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Babies and infants</strong>
                    <br />
                    Babies (up to 2 years of age) do not occupy a seat; the
                    price of the ticket is generally 10% of the official rate.
                    On certain flights children (from 2 to 11 years of age) may
                    be granted a reduction, except on charter flights.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Pregnancy</strong>
                    <br />
                    Different airlines have their own restrictions on when
                    pregnant woman may fly on their plane, which can range from
                    prohibiting flying anywhere from 7 to 30 days by the due
                    date. It is your responsibility to check the restrictions of
                    your particular airline. If you are denied boarding,
                    Millwoods will not be responsible for any resulting
                    cancellation fees and charges.
                  </p>
                </li>
              </ul>
            </li>
            <li id="representations-and-warranties">
              <strong>Representations and Warranties -</strong>{" "}
              <span>
                Traveler represents and warrants that Traveler: (i) has all
                requisite power and authority to enter into and perform the
                obligations under these Terms and Conditions; (ii) will abide by
                all laws, rules and regulations and will endeavor to comply with
                all local customs; and (iii) all of the information provided to
                Millwoods to facilitate Traveler’s Trip is true and accurate.
                Traveler authorizes Millwoods to make any such investigations as
                Millwoods determines to be necessary, at their sole discretion,
                to determine Travelers eligibility.
              </span>
            </li>
            <li id="medical-and-physical-conditions;-medical-emergencies">
              <strong>
                Medical and Physical Conditions; Medical Emergencies -
              </strong>{" "}
              <span>
                Medical Emergencies. Some activities available on Millwoods’s
                Trips are physically active and interactive, so you must be in
                good physical condition and health to participate in them.
                Various water activities and sports available on our Trips
                (including jet skiing, snorkeling, surfing, etc.) require
                various skills and abilities such as: the ability to swim, hand
                to eye coordination, balance, and an awareness of your
                surroundings. Traveler certifies they will not take any
                alcoholic beverages or drugs that may impair their physical or
                mental abilities before their participation in an adventure or
                water activity while on a Trip.
              </span>
              <p>
                Traveler certifies that they are responsible for managing their
                own medication and medical, physical, or allergic conditions
                during their Trip. Traveler understands that in the event of
                injury to Traveler, or exacerbation of Traveler’s medical
                condition, Millwoods may not be held responsible. If a serious
                emergency arises, it may be necessary for a physician to attend
                to Traveler, but Traveler recognizes that Millwoods is not
                obligated to take any action to facilitate or assist that
                treatment. The quality of medical personnel and facilities vary
                from region to region and cannot be controlled by Millwoods. In
                some parts of the world, substandard medical care is common and
                unavoidable. Hospital facilities are often unavailable and
                evacuation can be prolonged, difficult and expensive. Millwoods
                is not responsible for the costs of any medical treatment you
                may require during a Trip and assumes no liability regarding
                provision of medical care or lack thereof that you may receive
                while on the Trip. YOU ARE RESPONSIBLE FOR RISKS ASSOCIATED
                WITH, AND COSTS, OF ANY AND ALL MEDICAL TREATMENTS YOU MAY
                REQUIRE OR RECEIVE DURING YOUR TRIP. Traveler releases Millwoods
                from any liability relating to any such medical care, whether
                secured by a Service provider on behalf of Traveler, Millwoods,
                or otherwise, and agrees to be responsible for any and all
                expenses incurred for said medical care.
              </p>
            </li>
            <li id="disputes:-governing-law-jurisdiction-etc.">
              <strong>Disputes: Governing Law, Jurisdiction, etc. -</strong>{" "}
              <span>
                Governing Law, Jurisdiction, etc. These Terms and Conditions and
                the relationship between Traveler and Millwoods will be governed
                by the laws of the State of California without regard to its
                conflict of law provisions.
              </span>
              <p>
                Traveler and Millwoods agree to submit to the personal
                jurisdiction of the federal and state courts located in Santa
                Clara County, California with respect to any legal proceedings
                that may arise in connection with, or relate to, a Trip, these
                Terms and Conditions, our Privacy Policy, Millwoods’s website or
                any literature or materials concerning Millwoods and our Trips.
                Traveler and Millwoods agree to irrevocably submit to the
                jurisdiction of any such court in any such action, suit or
                proceeding and hereby agrees not to assert, by way of motion, as
                a defense or otherwise, in any such action, suit or proceeding,
                any claim that (i) he, she or it is not subject personally to
                the jurisdiction of such court, (ii) the venue is improper, or
                (iii) this agreement or the subject matter hereof may not be
                enforced in or by such court.
              </p>
            </li>
            <li id="attorney’s-fees-costs-and-expenses-of-suit">
              <strong>Attorney’s Fees, Costs, and Expenses of Suit -</strong>{" "}
              <span>
                If any act of law or equity, including an action for declaratory
                relief or any arbitration proceeding, is brought to enforce,
                interpret or construe the provisions of these Terms and
                Conditions, a Trip, our Privacy Policy, Millwoods’s website or
                any literature or materials concerning Millwoods, the prevailing
                party shall be entitled to recover actual reasonable attorney’s
                fees, costs, and expenses.
              </span>
            </li>
            <li id="assignment">
              <strong>Assignment -</strong>{" "}
              <span>
                Traveler may not assign his rights or obligations hereunder
                without the prior written consent of Millwoods.
              </span>
            </li>
            <li id="severability-and-survivability">
              <strong>Severability and Survivability -</strong>{" "}
              <span>
                If any provision, or portion of a provision, in these Terms and
                Conditions shall be unlawful, void, or for any reason
                unenforceable, then that provision shall be deemed severable and
                shall not affect the validity and enforceability of any
                remaining provisions. Traveler and Millwoods agree to substitute
                for such provision a valid provision which most closely
                approximates the intent and economic effect of such severed
                provision.
              </span>
              <p>
                Notwithstanding any other provisions of this these Terms and
                Conditions, or any general legal principles to the contrary, any
                provision of these Terms and Conditions that imposes or
                contemplates continuing obligations on a party will survive the
                expiration or termination of these Terms and Conditions.
              </p>
            </li>
            <li id="entire-agreement-waiver-etc.">
              <strong>Entire Agreement, Waiver, Etc. -</strong>{" "}
              <span>
                These Terms and Conditions constitute the entire understanding
                and agreement of the parties with respect to the subject matter
                covered by them, and supersede all prior and contemporaneous
                understandings and agreements, whether written or oral, with
                respect to such subject matter. No terms contained on any
                proposal, purchase order, acknowledgment or other document will
                be effective with respect to affecting the terms hereof. No
                delay or failure by either party to exercise or enforce at any
                time any right or provision hereof will be considered a waiver
                thereof of such party's rights thereafter to exercise or enforce
                each and every right and provision hereof. No single waiver will
                constitute a continuing or subsequent waiver. Millwoods does not
                guarantee it will take action against all breaches of these
                Terms and Conditions. No waiver, modification or amendment of
                any provision hereof will be effective unless it is in a writing
                signed by both the parties.
              </span>
            </li>{" "}
          </ol>
        </div>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
