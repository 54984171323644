import { Box, Container, Stack, alpha, lighten, useTheme } from "@mui/material";
import { connect } from "react-redux";
import {
  dictionariesProps,
  flightDataProps,
  flightSearchProps,
  loaderProps,
  reducerProps,
  routeProps,
} from "src/interfaces";
import {
  fetchCityAirportDictionaries,
  removeLoader,
  setActiveTab,
  setBookingFlight,
  setCurrentRoute,
  setFilterModal,
  setLoader,
  setShowDetails,
} from "src/store";
import { useEffect, useState } from "react";
import Filters from "./Filters";
import { FlightPagination, NoFlights, tabProps } from "./components";
import SearchArea from "../components/SearchArea";
import RenderItem from "../components/flightItem";
import RenderDetail from "../components/flightDetail";
import Modal from "src/components/Modal";
import Sorting from "./Sorting";
import {
  sortByDuration,
  sortByPrice,
  sortByPriceAndDuration,
} from "./constants";

const perPageCount = 5;

const Component = ({
  filteredData = [],
  dictionaries,
  cityAirportDictionaries,
  model,
  fetchCityAirportDictionaries,
  setBookingFlight,
  setRoute,
  setLoader,
  removeLoader,
  showDetails,
  setShowDetails,
  activeTab = "price",
  setActiveTab,
}: {
  filteredData: flightDataProps[];
  dictionaries: dictionariesProps;
  cityAirportDictionaries: any;
  model: flightSearchProps;
  fetchCityAirportDictionaries: (payload: {
    codes: any[];
    type: "A" | "C";
  }) => void;
  setBookingFlight: (item: any) => void;
  setRoute: (payload: routeProps) => void;
  setLoader: (payload: loaderProps) => void;
  removeLoader: () => void;
  showDetails: number[];
  setShowDetails: (payload: number[]) => void;
  activeTab: "price" | "time" | "price&time";
  setActiveTab: (payload: "price" | "time" | "price&time") => void;
}) => {
  const theme = useTheme();

  const totalPage: any = Math.ceil(
    (filteredData?.length / perPageCount) as any
  );

  const [freeCancellation, setFreeCancellation] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(1);
  }, [filteredData]);

  useEffect(() => {
    const codes = Object.keys(dictionaries?.locations || {});
    fetchCityAirportDictionaries({ codes: codes, type: "A" });
  }, [dictionaries?.locations]);

  const currentPageCount = (page - 1) * perPageCount;

  const bodyWidth = document.body.clientWidth;
  const [conntainerPadding, setContainerPadding] = useState({});
  useEffect(() => {
    if (bodyWidth < 991) {
      setContainerPadding({ px: 0 });
    } else {
      setContainerPadding({});
    }
  }, [bodyWidth]);

  const onBookClick = (item: any) => {
    setBookingFlight(item);
    setRoute({ path: "/flight-booking" });
  };

  const tabList: tabProps[] = [
    { title: "Cheapest", subTitle: "", value: "price" },
    { title: "Quickest", subTitle: "", value: "time" },
    { title: "Best", subTitle: "", value: "price&time" },
  ];

  const [tabs, setTabs] = useState<tabProps[]>([...tabList]);

  useEffect(() => {
    setLoader({ transparent: true });
    setTimeout(() => {
      removeLoader();
    }, 700);
  }, [activeTab]);

  return (
    <>
      <SearchArea />
      <Container
        maxWidth="lg"
        sx={{ ...conntainerPadding, mt: { md: 3, xs: 1 } }}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", my: { md: 2, xs: 0 } }}
        >
          <Box sx={{ width: "25%", display: { md: "block", xs: "none" } }}>
            <Filters setTabs={setTabs} />
          </Box>
          <Box sx={{ width: { md: "70%", xs: "100%" }, position: "relative" }}>
            {filteredData?.length > 0 && (
              <Box
                sx={{
                  px: { md: 0, xs: 0.5, textAlign: "center", width: "100%" },
                }}
              >
                <Sorting
                  tabs={tabs}
                  activeTab={activeTab}
                  onChangeTab={(value) => {
                    setActiveTab(value);
                    setPage(1);
                  }}
                />
              </Box>
            )}
            {filteredData?.length === 0 ? (
              <NoFlights />
            ) : (
              filteredData
                ?.sort(
                  activeTab === "price"
                    ? sortByPrice
                    : activeTab === "time"
                    ? sortByDuration
                    : sortByPriceAndDuration
                )
                ?.slice(currentPageCount, currentPageCount + perPageCount)
                ?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        mb: 0.5,
                        pt: { md: 1, xs: 1 },
                        pb: { md: 1, xs: 0.5 },
                        borderBottom: { md: "1px dashed gray" },
                        boxShadow:
                          theme.palette.mode === "dark"
                            ? `0 1px 0 ${alpha(
                                lighten(theme.colors.primary.main, 0.7),
                                0.15
                              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                            : `0px 2px 8px -3px ${alpha(
                                theme.colors.alpha.black[100],
                                0.2
                              )}, 0px 5px 22px -4px ${alpha(
                                theme.colors.alpha.black[100],
                                0.1
                              )}`,
                        pl: { md: 2, xs: 0 },
                      }}
                    >
                      <RenderItem
                        flightData={item}
                        dictionaries={dictionaries}
                        dataIndex={index}
                        onShowDetailClick={() => {
                          if (showDetails?.includes(index)) {
                            setShowDetails(
                              showDetails?.filter((ele) => ele != index)
                            );
                          } else {
                            setShowDetails([...showDetails, index]);
                          }
                        }}
                        showDetail={showDetails?.includes(index)}
                        oneway={model?.type === "oneway"}
                        cityAirportDictionaries={cityAirportDictionaries}
                        onShowCancellationDescription={() =>
                          setFreeCancellation(true)
                        }
                        onBookClick={onBookClick}
                      />
                      <Box
                        sx={{
                          height: showDetails?.includes(index) ? "auto" : 0,
                          transition: "all",
                          transitionDuration: 0.8,
                          pb: showDetails?.includes(index) ? 3 : 0,
                        }}
                      >
                        {showDetails?.includes(index) && (
                          <RenderDetail
                            flightData={item}
                            travelClass={model?.travelClass}
                            dataIndex={index}
                            oneway={model?.type === "oneway"}
                            dictionaries={dictionaries}
                            cityAirportDictionaries={cityAirportDictionaries}
                            showDetail={showDetails?.includes(index)}
                            onShowCancellationDescription={() =>
                              setFreeCancellation(true)
                            }
                            onBookClick={onBookClick}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })
            )}
            <Modal
              open={freeCancellation}
              onClose={() => setFreeCancellation(false)}
            >
              <Box sx={{ p: 5 }}>
                Book now and if your plans change, you can cancel for no charge
                by calling us within 3 hours or for fee within 12 hours of
                booking.
              </Box>
            </Modal>
            <Box
              sx={{
                mt: 6,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FlightPagination
                count={totalPage > 0 ? totalPage : 1}
                page={page}
                setPage={(value) => {
                  setPage(value);
                  setShowDetails([]);
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

const mapStateToProps = ({
  flights: { list, filteredList, searchModel },
  locations: { cityAirportDictionaries },
  showDetails,
  activeTab,
  filterModal,
}: reducerProps) => ({
  filteredData: filteredList?.data,
  dictionaries: list?.dictionaries,
  model: searchModel,
  cityAirportDictionaries,
  showDetails,
  activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  setShowDetails: (payload: any) => dispatch(setShowDetails(payload)),
  setActiveTab: (payload: any) => dispatch(setActiveTab(payload)),
  setRoute: (payload: any) => dispatch(setCurrentRoute(payload)),
  setLoader: (payload: any) => dispatch(setLoader(payload)),
  removeLoader: () => dispatch(removeLoader()),
  setBookingFlight: (payload: any) => dispatch(setBookingFlight(payload)),
  fetchCityAirportDictionaries: (payload: any) =>
    dispatch(fetchCityAirportDictionaries(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
