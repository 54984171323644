import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, alpha, useTheme } from "@mui/material";
import { tabProps } from "./components";

const Component = ({
  tabs = [],
  activeTab,
  onChangeTab,
}: {
  tabs: tabProps[];
  activeTab: string;
  onChangeTab: Function;
}) => {
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onChangeTab(newValue);
  };

  return (
    <Tabs
      indicatorColor={"transparent" as any}
      sx={{ width: "100%" }}
      value={activeTab}
      onChange={handleChange}
    >
      {tabs?.map((item, index) => {
        return (
          <Tab
            sx={{
              width: "33%",
              backgroundColor:
                item?.value === activeTab
                  ? alpha(theme?.colors?.secondary?.main, 0.25)
                  : theme?.colors?.alpha?.gray[10],
              borderLeft: `1px solid ${theme?.colors?.alpha?.gray[50]}`,
              borderRight: `1px solid ${theme?.colors?.alpha?.gray[50]}`,
            }}
            key={index}
            label={
              <Box sx={{ color: theme?.colors?.alpha?.black[100] }}>
                <Box>{item?.title}</Box>
                <Box sx={{ color: theme?.colors?.alpha?.gray[70] }}>
                  {item?.subTitle ? `$ ${item?.subTitle}` : ""}
                </Box>
              </Box>
            }
            value={item?.value}
          />
        );
      })}
    </Tabs>
  );
};

export default Component;
