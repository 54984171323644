import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  FormControlLabel,
  Menu,
  Stack,
  TextField,
  alpha,
  lighten,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import {
  fetchFlights,
  fetchAirportsCities,
  setSearchModel,
  setCurrentRoute,
  setActiveTab,
  setShowDetails,
  setFilterModal,
} from "src/store";
import { inputs, passengersDetail } from "./constants";
import { PrimaryButton, PrimaryIconButton } from "src/components/Buttons";
import { Checkbox } from "src/components";
import PassengerMenuItem from "./PassengerMenuItem";
import {
  flightSearchProps,
  locationReducer,
  reducerProps,
  routeProps,
} from "src/interfaces";
import moment from "moment";
import { Calendar, DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { useLocation } from "react-router";
import EditIcon from "@mui/icons-material/BorderColorOutlined";
import FilterIcon from "@mui/icons-material/FilterAlt";
import Modal from "src/components/Modal";
import { useSearchParams } from "react-router-dom";

const searchInitialState = {
  type: "oneway",
  adults: 1,
  children: 0,
  infants: 0,
  travelClass: "ECONOMY",
  origin: "",
  originName: "",
  destination: "",
  destinationName: "",
  nonStop: false,
  departureDate: new Date(),
  returnDate: "",
  maxNumberOfConnections: 2,
  includedCheckedBagsOnly: false,
  includedCarrierCodes: [],
  duration: 0,
  layover: [0, 0],
  outbound: [0, 0],
  filtering: false,
  localFiltering: false,
} as any;

const Component = ({
  model = {} as any,
  locations = {},
  setModel,
  setRoute,
  fetchAirportsCities,
  fetchFlightOffers,
  setIsRoundTrip,
  setShowDetails,
  setFilterModal,
  setActiveTab,
}: {
  model: flightSearchProps;
  locations: locationReducer;
  setModel: (payload: flightSearchProps) => void;
  setRoute: (payload: routeProps) => void;
  fetchAirportsCities: (payload: any) => void;
  fetchFlightOffers: (payload: any) => void;
  setIsRoundTrip?: Function;
  setShowDetails: (payload: number[]) => void;
  setFilterModal: (payload: boolean) => void;
  setActiveTab: (payload: "price" | "time" | "price&time") => void;
}) => {
  const onSearchClick = () => {
    setActiveTab("price");
    setShowDetails([]);
  };
  const location = useLocation();
  const pathname = location?.pathname;
  const isHome = pathname == "/";

  const [searchParams] = useSearchParams();

  const [searchModal, setSearchModal] = useState<boolean>(false);
  const [tempModel, setTempModel] = useState<flightSearchProps>(model);

  const [calendarDirection, setCalendarDirection] = useState<
    "vertical" | "horizontal"
  >("horizontal");
  const theme = useTheme();

  const adults = tempModel?.adults || 1;
  const children = tempModel?.children || 0;
  const infants = tempModel?.infants || 0;

  const passengers = (adults + children + infants).toString();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [calendarVisible, setCalendarVisible] = React.useState<{
    anchorEl: null | HTMLElement;
    from: string;
  }>({ anchorEl: null, from: "departureDate" });

  const handleCitySearch = async (from: string, keyword: string) => {
    if (keyword?.length < 3) {
      return;
    }
    setTempModel({
      ...tempModel,
      [from]: "",
      [from + "Name"]: "",
    });

    fetchAirportsCities({
      keyword,
      key: from + "s",
    });
  };

  useEffect(() => {
    if (searchParams?.size > 0 && !isHome) {
      let payload = tempModel;
      Object?.keys(tempModel)?.map((key) => {
        payload = {
          ...payload,
          [key]: JSON?.parse(searchParams?.get(key)) || payload[key],
        };
      });
      setTempModel(payload);
      setModel(payload);
      submitHandler(payload);
    } else if (isHome) {
      setTempModel(searchInitialState);
      setIsRoundTrip(searchInitialState?.type === "round");
    }
  }, [pathname, isHome]);

  const bodyWidth = document.body.clientWidth;
  useEffect(() => {
    if (bodyWidth < 991) {
      setCalendarDirection("vertical");
    } else {
      setCalendarDirection("horizontal");
    }
  }, [bodyWidth]);

  const onTypeChange = (type) => {
    setIsRoundTrip && setIsRoundTrip(type === "round");
    if (type === "round") {
      const startDate = addDays(new Date(), 7);
      setTempModel({
        ...tempModel,
        type: type,
        departureDate: startDate,
        returnDate: addDays(startDate, 7),
      });
    } else {
      setTempModel({
        ...tempModel,
        type: type,
        departureDate: addDays(new Date(), 0),
        returnDate: "",
      });
    }
  };

  const handleChange = (event: any) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    let dependencyChange = {};
    if (name === "adults" && value < tempModel?.infants) {
      dependencyChange = { infants: value };
    }
    setTempModel({
      ...tempModel,
      ...dependencyChange,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event?.preventDefault();
    setSearchModal(false);
    onSearchClick();
    submitHandler(tempModel);
    let urlPayload = "";
    Object?.keys(tempModel)?.map((key) => {
      if (tempModel[key]) {
        urlPayload += urlPayload
          ? `&${key}=${JSON?.stringify(tempModel[key])}`
          : `?${key}=${JSON?.stringify(tempModel[key])}`;
      }
    });
    setRoute({ path: "flights" + urlPayload });
  };

  const submitHandler = (finalPayload) => {
    let departureDate = moment(finalPayload?.departureDate).format(
      "YYYY-MM-DD"
    );
    let returnDate =
      finalPayload?.type === "round" && finalPayload?.returnDate
        ? moment(finalPayload?.returnDate).format("YYYY-MM-DD")
        : "";
    const payload: flightSearchProps = {
      ...finalPayload,
      filtering: false,
      localFiltering: false,
      includedCarrierCodes: [],
      maxNumberOfConnections: finalPayload?.nonStop ? 0 : 2,
      departureDate,
      returnDate,
    };
    fetchFlightOffers(payload);
  };
  const searchUI = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        backgroundImage: "linear-gradient(#073662, #226BAF)",
        borderRadius: isHome ? 1 : 0,
        px: { md: isHome ? 5 : 8, xs: 1 },
        py: isHome ? 5 : 2,
        width: "100%",
        color: "white",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: { md: "flex" },
            mt: { xs: 1, md: 0 },
            "*": {
              color: "white !important",
              "&:before": {
                borderBottom: "none !important",
              },
              "&:after": {
                borderBottomColor: "white !important",
              },
            },
          }}
        >
          <FormControl
            variant="standard"
            sx={{
              mx: 1,
              minWidth: { md: 120, xs: "100%" },
              mb: { md: 0, xs: 1 },
            }}
          >
            <Select
              value={tempModel?.type}
              onChange={(event) => onTypeChange(event?.target?.value)}
              name="type"
              label=""
              required
            >
              <MenuItem value={"oneway"}>One Way</MenuItem>
              <MenuItem value={"round"}>Round Trip</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              svg: anchorEl && {
                transform: "rotate(180deg)",
              },
            }}
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                mx: 1,
                minWidth: { md: 120, xs: "100%" },
                mb: { md: 0, xs: 1 },
              }}
            >
              <Select value={passengers} required readOnly>
                <MenuItem value={passengers}>
                  <Box component="span">Travelers </Box>
                  <Box component="span" sx={{ ml: 1 }}>
                    {passengers}{" "}
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            sx={{
              mt: { md: 0.5, xs: 0 },
              ".MuiPaper-root": {
                borderRadius: 0,
                py: 0,
                px: 2,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? `0 1px 0 ${alpha(
                        lighten(theme.colors.primary.main, 0.7),
                        0.3
                      )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                    : `0px 2px 8px -3px ${alpha(
                        theme.colors.alpha.black[100],
                        0.2
                      )}, 0px 5px 22px -4px ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}`,
              },
            }}
          >
            {passengersDetail?.map((item, index) => {
              let max = 9;
              let min = 0;
              if (item?.key === "adults") {
                min = 1;
              }
              if (item?.key === "infants") {
                max = tempModel?.adults;
              }
              return (
                <PassengerMenuItem
                  disabledMinus={
                    tempModel[item?.key] === min || parseInt(passengers) === 1
                  }
                  disabledPlus={
                    parseInt(passengers) >= 9 || tempModel[item?.key] >= max
                  }
                  key={index}
                  value={tempModel[item?.key] || 0}
                  onMinusPress={() =>
                    handleChange({
                      target: {
                        name: item?.key,
                        value: !tempModel[item?.key]
                          ? 1
                          : tempModel[item?.key] - 1,
                      },
                    })
                  }
                  onPlusPress={() =>
                    handleChange({
                      target: {
                        name: item?.key,
                        value: !tempModel[item?.key]
                          ? 1
                          : tempModel[item?.key] + 1,
                      },
                    })
                  }
                  {...item}
                />
              );
            })}
          </Menu>
          <FormControl
            variant="standard"
            sx={{
              mx: 1,
              minWidth: { md: 120, xs: "100%" },
              mb: { md: 0, xs: 1 },
            }}
          >
            <Select
              value={tempModel?.travelClass}
              onChange={handleChange}
              name="travelClass"
              label=""
              required
            >
              <MenuItem value={"ECONOMY"}>Economy</MenuItem>
              <MenuItem value={"PREMIUM_ECONOMY"}>Premium Economy</MenuItem>
              <MenuItem value={"BUSINESS"}>Business</MenuItem>
              <MenuItem value={"FIRST"}>First Class</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: { md: "flex" },
            alignItems: "center",
            "*": {
              borderColor: "white !important",
            },
            ".MuiInputBase-fullWidth, .MuiInputBase-formControl": {
              backgroundColor: "white !important",
            },
            ".MuiOutlinedInput-root": {
              borderRadius: 0,
            },
          }}
        >
          {inputs(tempModel)?.map((item, index) => {
            if (item?.type === "autocomplete") {
              return (
                <Autocomplete
                  {...item}
                  loading={locations?.loading}
                  loadingText={<CircularProgress size={20} color="secondary" />}
                  key={index}
                  sx={{
                    width: { md: item?.minWidth || "40%", xs: "100%" },
                    marginLeft: { md: index === 0 ? 0 : 1, xs: 0 },
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  id={item?.key + "-datalist"}
                  value={
                    !tempModel[item?.key] ||
                    locations[item?.key + "s"]?.length === 0 ||
                    !locations[item?.key + "s"]?.find(
                      (ele) => ele?.value === tempModel[item?.key]
                    )
                      ? null
                      : {
                          label: tempModel[item?.key + "Name"],
                          value: tempModel[item?.key],
                        }
                  }
                  options={locations[item?.key + "s"] || []}
                  onChange={(e, option) => {
                    setTempModel({
                      ...tempModel,
                      [item?.key]: option?.value,
                      [item?.key + "Name"]: option?.label,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(event) => {
                        const value = event?.target?.value;
                        handleCitySearch(item?.key, value);
                      }}
                      onKeyDown={(e) => {
                        if (!/[0-9a-zA-Z]/.test(e.key) && e.key != " ") {
                          e.preventDefault();
                        }
                      }}
                      sx={{ mt: { md: 1, xs: 0 } }}
                      fullWidth
                      required
                      margin="normal"
                      id={item?.key}
                      name={item?.key}
                      placeholder={item?.label}
                    />
                  )}
                />
              );
            }
          })}
          <Box
            sx={{
              position: "relative",
              width: { md: "40%", xs: "100%" },
              marginLeft: { md: 1, xs: 0 },
            }}
          >
            <TextField
              value={moment(tempModel?.departureDate).format("YYYY-MM-DD")}
              type={"date"}
              fullWidth
              required
              sx={{ mt: { md: 1, xs: 0 } }}
              margin="normal"
              id={"departureDate"}
              label=""
              name={"departureDate"}
            />
            <Box
              onClick={(event: any) => {
                setCalendarVisible({
                  anchorEl: event?.currentTarget,
                  from: "departureDate",
                });
              }}
              sx={{
                backgroundColor: "transparent",
                zIndex: 2,
                height: 50,
                width: "100%",
                position: "absolute",
                bottom: 10,
              }}
            />
          </Box>
          {tempModel?.type === "round" && (
            <Box
              sx={{
                position: "relative",
                width: { md: "40%", xs: "100%" },
                marginLeft: { md: 1, xs: 0 },
              }}
            >
              <TextField
                sx={{ mt: { md: 1, xs: 0 } }}
                value={
                  tempModel?.returnDate
                    ? moment(tempModel?.returnDate).format("YYYY-MM-DD")
                    : ""
                }
                type={"date"}
                fullWidth
                required
                margin="normal"
                id={"returnDate"}
                label=""
                name={"returnDate"}
              />
              <Box
                onClick={(event: any) => {
                  setCalendarVisible({
                    anchorEl: event?.currentTarget,
                    from: "returnDate",
                  });
                }}
                sx={{
                  backgroundColor: "transparent",
                  zIndex: 2,
                  height: 50,
                  width: "100%",
                  position: "absolute",
                  bottom: 10,
                }}
              />
            </Box>
          )}
          <Menu
            anchorEl={calendarVisible?.anchorEl}
            open={Boolean(calendarVisible?.anchorEl)}
            onClose={() =>
              setCalendarVisible({
                anchorEl: null,
                from: "departureDate",
              })
            }
            sx={{
              mt: { md: 1, xs: -3 },
              ".MuiList-root": {
                pt: 0,
              },
              ".MuiPaper-root": {
                overflowY: "hidden",
                borderRadius: 0,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? `0 1px 0 ${alpha(
                        lighten(theme.colors.primary.main, 0.7),
                        0.3
                      )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                    : `0px 2px 8px -3px ${alpha(
                        theme.colors.alpha.black[100],
                        0.2
                      )}, 0px 5px 22px -4px ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}`,
              },
            }}
          >
            {tempModel?.type === "round" &&
            calendarVisible?.from === "departureDate" ? (
              <DateRange
                onRangeFocusChange={(e) => {
                  if (e.length > 1 && e[1] === 0) {
                    setCalendarVisible({
                      anchorEl: null,
                      from: "departureDate",
                    });
                  }
                }}
                onChange={(item) => {
                  setTempModel({
                    ...tempModel,
                    departureDate: item?.selection?.startDate,
                    returnDate: item?.selection?.endDate,
                  });
                }}
                direction={calendarDirection}
                minDate={new Date()}
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 2))
                }
                ranges={[
                  {
                    startDate: new Date(tempModel?.departureDate),
                    endDate: new Date(tempModel?.returnDate),
                    key: "selection",
                  },
                ]}
                months={2}
                scroll={{ enabled: calendarDirection === "vertical" }}
              />
            ) : (
              <Calendar
                onChange={(date) => {
                  setCalendarVisible({
                    anchorEl: null,
                    from: "departureDate",
                  });
                  handleChange({
                    target: {
                      name: calendarVisible?.from,
                      value: date,
                    },
                  });
                }}
                direction={calendarDirection}
                minDate={
                  tempModel?.type === "round"
                    ? new Date(tempModel?.departureDate)
                    : new Date()
                }
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 2))
                }
                date={new Date(tempModel[calendarVisible?.from]) || new Date()}
                months={2}
                scroll={{ enabled: calendarDirection === "vertical" }}
              />
            )}
          </Menu>
          <PrimaryIconButton
            type="submit"
            size="medium"
            sx={{
              display: { xs: "none", md: "block" },
              borderRadius: 0,
              py: 1.29,
              px: 1.6,
              mt: { md: 0, xs: 1 },
            }}
          >
            <SearchIcon fontSize="medium" sx={{ color: "white" }} />
          </PrimaryIconButton>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            sx={{
              ml: { md: "auto" },
              mr: { md: "10%" },
            }}
            control={
              <Checkbox
                sx={{ color: "white" }}
                checked={tempModel?.nonStop}
                onChange={() =>
                  handleChange({
                    target: {
                      name: "nonStop",
                      value: !tempModel?.nonStop,
                    },
                  })
                }
              />
            }
            label="Non-Stop Flight only"
          />
        </Box>
        <PrimaryButton
          type="submit"
          size="medium"
          fullWidth
          sx={{
            display: { xs: "flex", md: "none" },
            borderRadius: 0,
            alignItems: "center",
            py: 1.3,
            mt: 0.4,
          }}
        >
          <SearchIcon fontSize="medium" sx={{ color: "white" }} />
          <Box sx={{ ml: 1 }}>Search</Box>
        </PrimaryButton>
      </Container>
    </Box>
  );
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: "linear-gradient(#073662, #226BAF)",
          py: 2,
          width: "100%",
          color: "white",
          display: { md: "none", xs: isHome ? "none" : "block" },
        }}
      >
        <Container maxWidth="lg" sx={{ px: 1.35 }}>
          <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
            <Box
              component="div"
              sx={{
                cursor: "pointer",
                width: "80%",
                ".MuiBox-root": { textWrap: "nowrap", overflow: "hidden" },
                flexWrap: "nowrap",
              }}
              onClick={() => setSearchModal(true)}
            >
              <Stack direction="row" alignItems="center">
                <Box>
                  {model?.originName
                    ? `${model?.originName?.split(",")[0]} (${model?.origin})`
                    : model?.origin}
                </Box>
                <Box
                  sx={{
                    backgroundColor: theme?.colors?.secondary?.light,
                    width: 20,
                    minWidth: 20,
                    mx: 1,
                    height: 2,
                  }}
                />
                <Box>
                  {model?.destinationName
                    ? `${model?.destinationName?.split(",")[0]} (${
                        model?.destination
                      })`
                    : model?.destination}
                </Box>
                <PrimaryIconButton
                  onClick={() => setSearchModal(true)}
                  sx={{
                    backgroundColor: theme?.colors?.alpha?.white[0],
                    ml: 0.2,
                    p: 0.4,
                  }}
                >
                  <EditIcon fontSize="small" />
                </PrimaryIconButton>
              </Stack>
              {model?.type === "round" && (
                <Stack direction="row" alignItems="center">
                  <Box>
                    {model?.destinationName
                      ? `${model?.destinationName?.split(",")[0]} (${
                          model?.destination
                        })`
                      : model?.destination}
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: theme?.colors?.secondary?.light,
                      width: 20,
                      minWidth: 20,
                      mx: 1,
                      height: 2,
                    }}
                  />
                  <Box>
                    {model?.originName
                      ? `${model?.originName?.split(",")[0]} (${model?.origin})`
                      : model?.origin}
                  </Box>
                </Stack>
              )}
              <Stack
                spacing={0.3}
                direction="row"
                alignItems="center"
                sx={{
                  overflow: "hidden",
                  ".MuiBox-root": { textWrap: "nowrap" },
                }}
              >
                {/* <Box>{moment(model?.departureDate)?.format("MM/DD/yyyy")}</Box>
                {model?.returnDate && <Box>to</Box>}
                {model?.returnDate && (
                  <Box>{moment(model?.returnDate)?.format("MM/DD/yyyy")}</Box>
                )} */}
                {model?.adults > 0 && (
                  <Box sx={{ flexWrap: "nowrap", display: "inline-block" }}>
                    Adults: {model?.adults}
                  </Box>
                )}
                {model?.children > 0 && <Box>Childrens: {model?.children}</Box>}
                {model?.infants > 0 && <Box>Infants: {model?.infants}</Box>}
              </Stack>
            </Box>
            <Box sx={{ ml: "auto" }}>
              <PrimaryIconButton
                onClick={() => setFilterModal(true)}
                sx={{ borderRadius: 0.2, textTransform: "capitalize", px: 1 }}
              >
                <FilterIcon
                  fontSize="small"
                  sx={{ color: theme?.colors?.alpha?.white[100] }}
                />
              </PrimaryIconButton>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Box sx={{ display: { md: "block", xs: isHome ? "block" : "none" } }}>
        {searchUI}
      </Box>
      <Modal open={searchModal} onClose={() => setSearchModal(false)}>
        {searchUI}
      </Modal>
    </Box>
  );
};

const mapStateToProps = ({
  flights: { searchModel, list },
  locations,
}: reducerProps) => ({
  data: list?.data,
  model: searchModel,
  locations,
});
const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (payload: any) => dispatch(setActiveTab(payload)),
  setShowDetails: (payload: any) => dispatch(setShowDetails(payload)),
  setFilterModal: (payload: any) => dispatch(setFilterModal(payload)),
  setModel: (payload: any) => dispatch(setSearchModel(payload)),
  setRoute: (payload: any) => dispatch(setCurrentRoute(payload)),
  fetchFlightOffers: (payload: any) => dispatch(fetchFlights(payload)),
  fetchAirportsCities: (payload: any) => dispatch(fetchAirportsCities(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
