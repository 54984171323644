import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";

export const links1 = [
  {
    label: "Search Flights",
    value: "#",
  },
  {
    label: "Best Offers",
    value: "#",
  },
  {
    label: "Apply Coupon",
    value: "#",
  },
  {
    label: "Contact Us",
    value: "#",
  },
  {
    label: "About Us",
    value: "#",
  },
];

export const links2 = [
  {
    label: "Helps",
    value: "#",
  },
  {
    label: "FAQ",
    value: "#",
  },
  {
    label: "Blogs",
    value: "#",
  },
  {
    label: "Press",
    value: "#",
  },
];
export const links3 = [
  {
    label: "Airline fees",
    value: "#",
  },
  {
    label: "Airlines",
    value: "#",
  },
  {
    label: "Low fare tips",
    value: "#",
  },
];

export const socialMedia = [
  {
    icon: <FacebookIcon />,
    value: "#",
  },
  {
    icon: <InstagramIcon />,
    value: "#",
  },
];
