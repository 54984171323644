import { SET_ACTIVE_TAB, SET_FILTER_MODAL, SET_SHOW_DETAILS } from "./types";

export const setFilterModal = (payload: boolean) => {
  return {
    type: SET_FILTER_MODAL,
    payload: payload,
  };
};
export const setActiveTab = (payload: "price" | "time" | "price&time") => {
  return {
    type: SET_ACTIVE_TAB,
    payload: payload,
  };
};
export const setShowDetails = (payload: number[]) => {
  return {
    type: SET_SHOW_DETAILS,
    payload: payload,
  };
};
