import { Box, BoxProps, Stack, SxProps, useTheme } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const Column = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        maxWidth: { md: "25%", xs: "100%" },
        width: { md: "25%", xs: "100%" },
        mr: 2,
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

export const Stops = ({
  stops,
  sx,
  stackSx,
  spacing = 10,
}: {
  stops: number;
  sx?: SxProps;
  stackSx?: SxProps;
  spacing?: number;
}) => {
  const theme = useTheme();
  const numberOfStops: any[] = [];
  for (let i = 0; i < stops; i++) {
    numberOfStops.push(
      <Box
        key={i}
        sx={{
          width: 10,
          minWidth: 10,
          height: 10,
          minHeight: 10,
          borderRadius: 10,
          backgroundColor: theme?.colors?.primary?.main,
          position: "relative",
          ...sx,
        }}
      />
    );
  }
  return (
    <Stack
      sx={{
        postion: "absolute",
        mt: -0.6,
        width: `${spacing * stops * stops}%`,
        alignItems: "center",
        mx: "auto",
        justifyContent: false ? "center" : "space-between",
        ...stackSx,
      }}
      direction="row"
    >
      {numberOfStops}
    </Stack>
  );
};

export const FilterSectionTitle = ({ title }: { title?: any }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        maxWidth: "50%",
        color: theme?.colors?.alpha?.white[100],
        px: 1.5,
        py: 0.5,
        // backgroundImage: 'url(/assets/img/filter-section-title-bg.svg)',
        backgroundColor: theme?.colors?.secondary?.main,
      }}
    >
      {title}
    </Box>
  );
};

export const FilterSectionSubTitle = ({ title }: { title?: any }) => {
  const theme = useTheme();
  return (
    <Box sx={{ color: theme?.colors?.secondary?.main, mb: 0.5 }}>{title}</Box>
  );
};

export const FlightPagination = ({
  count = 1,
  page = 1,
  setPage,
}: {
  count: number;
  page: number;
  setPage?: Function;
}) => {
  return (
    <Stack sx={{ position: "absolute", bottom: 0 }} spacing={2}>
      <Pagination
        count={count}
        page={page}
        onChange={(e, value) => {
          window?.scrollTo(0, 0);
          setPage(value);
        }}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
};

export const NoFlights = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        mt: { md: "30%", xs: "10%" },
        mb: { xs: "15%", md: 0 },
        fontWeight: "bold",
        fontSize: 20,
      }}
    >
      No Flights Found
    </Box>
  );
};

export interface tabProps {
  title: string;
  subTitle: string;
  value: "price" | "time" | "price&time";
}
