import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";

import BaseLayout from "./layouts/BaseLayout";
import UserLayout from "./layouts/UserLayout";
import SidebarLayout from "./layouts/SidebarLayout";
import FlightsList from "./views/website/flightsList";
import FlightBooking from "./views/website/flightBooking";
import BookingSuccess from "./views/website/bookingSuccess";
import BookingRules from "./views/website/bookingRules";
import TermsAndConditions from "./views/website/termsAndConditions";
import PrivacyPolicy from "./views/website/privacyPolicy";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
// Authentications //

// Import
const LoginScreen = Loader(
  lazy(() => import("src/views/authentication/login"))
);
const VerifyEmail = Loader(
  lazy(() => import("src/views/authentication/verifyEmailAction"))
);
const ForgotPassword = Loader(
  lazy(() => import("src/views/authentication/forgotPassword"))
);
const ChangePassword = Loader(
  lazy(() => import("src/views/authentication/changePassword"))
);

const Home = Loader(lazy(() => import("src/views/website/home")));

// Admin //

const Bookings = Loader(lazy(() => import("src/views/admin/bookings")));
const DashboardCrypto = Loader(lazy(() => import("src/views/admin/dashboard")));

const routes = (role?: string, isLoggedIn: boolean = false): RouteObject[] => {
  let routesList: RouteObject[] = [
    {
      path: "",
      element: <UserLayout />,
      children: [
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
        {
          path: "",
          element: <Home />,
        },
        {
          path: "flights",
          element: <FlightsList />,
        },
        {
          path: "flight-booking",
          element: <FlightBooking />,
        },
        {
          path: "flight-booking-success",
          element: <BookingSuccess />,
        },
        {
          path: "booking-rules",
          element: <BookingRules />,
        },
        {
          path: "terms-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
      ],
    },
  ];
  if (!isLoggedIn) {
    routesList = [
      ...routesList,
      {
        path: "login",
        element: <LoginScreen />,
      },
      {
        path: "verifyemail",
        element: <VerifyEmail />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
    ];
  }
  if (isLoggedIn) {
    routesList = [
      ...routesList,
      {
        path: "setting",
        element: <SidebarLayout />,
        children: [
          {
            path: "change-password",
            element: <ChangePassword />,
          },
        ],
      },
    ];
    if (role === "admin") {
      routesList = [
        ...routesList,
        {
          path: "*",
          element: <Navigate to="admin/dashboard" replace />,
        },
        {
          path: "admin",
          element: <SidebarLayout />,
          children: [
            {
              path: "dashboard",
              element: <DashboardCrypto />,
            },
            {
              path: "bookings",
              element: <Bookings />,
            },
          ],
        },
      ];
    }
  }
  return routesList;
};

export default routes;
