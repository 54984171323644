import { firebase } from "./http";

export const loadBookings = async () => {
  try {
    const users = await firebase.GetDocs("bookings");
    return users;
  } catch (error) {
    throw error;
  }
};
