import { Box, Container, SxProps } from "@mui/material";

const Banner = ({
  children,
  imageSx,
  colorSx,
  url,
}: {
  children: any;
  imageSx?: SxProps;
  colorSx?: SxProps;
  url?: string;
}) => {
  return (
    <Box
      sx={{
        backgroundImage: url ? `url(${url})` : `url(/assets/img/banner-bg.png)`,
        backgroundSize: "cover",
        width: "100%",
        ...imageSx,
      }}
    >
      <Box
        sx={{
          backgroundImage: "linear-gradient(#073662d6, #07366280)",
          py: 8,
          width: "100%",
          height: "100%",
          mt: 3,
          ...colorSx,
        }}
      >
        <Container maxWidth="lg">{children}</Container>
      </Box>
    </Box>
  );
};

export default Banner;
