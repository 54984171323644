import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import createSagaMiddleware from "redux-saga";

import * as allReducers from "./reducers";
import rootSaga from "./sagas";
import { reducerProps } from "src/interfaces";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "flightbooking-root",
  storage,
  blacklist: [
    "loader",
    "alert",
    "route",
    "activeTab",
    "filterModal",
    "showDetails",
  ],
};
const reducers = combineReducers<reducerProps>(allReducers);

const rootReducer = (state: reducerProps, action) => {
  // if (action?.type === REMOVE_AUTH_USER) {
  //   return reducers(undefined, action);
  // }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const ReduxProvider = ({ children }): JSX.Element => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
export default ReduxProvider;
