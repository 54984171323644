import { Box, BoxProps } from "@mui/material";

const SectionContainer = (props: BoxProps) => {
  return (
    <Box {...props} sx={{ mb: "6.75rem", position: "relative", ...props?.sx }}>
      {props?.children}
    </Box>
  );
};

export default SectionContainer;
