import { flightSearchProps } from "src/interfaces";
import {
  CREATE_FLIGHT_ORDER,
  FETCH_DEAL_FLIGHTS,
  REMOVE_BOOKED_FLIGHT,
  REMOVE_BOOKING_FLIGHT,
  REMOVE_FLIGHTS,
  RESET_SEARCH_MODEL,
  SEARCH_FLIGHTS,
  SET_BOOKED_FLIGHT,
  SET_BOOKING_FLIGHT,
  SET_DEAL_FLIGHTS,
  SET_FILTERED_FLIGHTS,
  SET_FLIGHTS,
  SET_SEARCH_MODEL,
} from "./types";

export const setSearchModel = (payload: flightSearchProps) => {
  return {
    type: SET_SEARCH_MODEL,
    payload,
  };
};

export const resetSearchModel = () => {
  return {
    type: RESET_SEARCH_MODEL,
  };
};

export const fetchFlights = (payload: any) => {
  return {
    type: SEARCH_FLIGHTS,
    payload,
  };
};

export const setFlights = (payload: any) => {
  return {
    type: SET_FLIGHTS,
    payload,
  };
};

export const removeFlights = () => {
  return {
    type: REMOVE_FLIGHTS,
  };
};

export const setFilteredFlights = (payload: any) => {
  return {
    type: SET_FILTERED_FLIGHTS,
    payload,
  };
};

export const fetchDealFlights = (payload: any) => {
  return {
    type: FETCH_DEAL_FLIGHTS,
    payload,
  };
};

export const setDealFlights = (payload: any) => {
  return {
    type: SET_DEAL_FLIGHTS,
    payload,
  };
};

export const setBookingFlight = (payload: any) => {
  return {
    type: SET_BOOKING_FLIGHT,
    payload,
  };
};

export const removeBookingFlight = () => {
  return {
    type: REMOVE_BOOKING_FLIGHT,
  };
};

export const createFlightOrder = (payload: any) => {
  return {
    type: CREATE_FLIGHT_ORDER,
    payload,
  };
};

export const setBookedFlight = (payload: any) => {
  return {
    type: SET_BOOKED_FLIGHT,
    payload,
  };
};

export const removeBookedFlight = () => {
  return {
    type: REMOVE_BOOKED_FLIGHT,
  };
};
