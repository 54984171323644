export const SET_SEARCH_MODEL = "SET_SEARCH_MODEL";
export const RESET_SEARCH_MODEL = "RESET_SEARCH_MODEL";
export const SEARCH_FLIGHTS = "SEARCH_FLIGHTS";
export const FETCH_DEAL_FLIGHTS = "FETCH_DEAL_FLIGHTS";
export const SET_DEAL_FLIGHTS = "SET_DEAL_FLIGHTS";
export const SET_FLIGHTS = "SET_FLIGHTS";
export const REMOVE_FLIGHTS = "REMOVE_FLIGHTS";
export const SET_BOOKING_FLIGHT = "SET_BOOKING_FLIGHT";
export const REMOVE_BOOKING_FLIGHT = "REMOVE_BOOKING_FLIGHT";
export const SET_FILTERED_FLIGHTS = "SET_FILTERED_FLIGHTS";
export const CREATE_FLIGHT_ORDER = "CREATE_FLIGHT_ORDER";
export const SET_BOOKED_FLIGHT = "SET_BOOKED_FLIGHT";
export const REMOVE_BOOKED_FLIGHT = "REMOVE_BOOKED_FLIGHT";
