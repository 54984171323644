import { flightProps, flightSearchProps } from "src/interfaces";
import { fetch } from "./http";
import { store } from "src/store";
import { createMailTemplate } from "src/helpers";

const controller: string = "v2/shopping/";

export const fetchFlightOffersByGet = async (
  payload: flightSearchProps,
  token: string
) => {
  try {
    const response = await fetch.Get(
      controller +
        `flight-offers?originLocationCode=${
          payload?.origin
        }&destinationLocationCode=${payload?.destination}&departureDate=${
          payload?.departureDate
        }${
          payload?.returnDate ? "&returnDate=" + payload?.returnDate : ""
        }&adults=${payload?.adults}&children=${
          payload?.children || 0
        }&infants=${payload?.infants || 0}&travelClass=${
          payload?.travelClass
        }&nonStop=${payload?.nonStop || false}&currencyCode=${
          payload?.currencyCode || "CAD"
        }${payload?.max ? "&max=" + payload?.max : ""}`,
      token
    );
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 201) {
      const data = await response.json();
      throw new Error(data);
    } else if (response?.status != 429 && response?.status != 401) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFlightOffersByPost = async (
  payload: flightSearchProps,
  token: string
) => {
  try {
    let travelers = [];
    let i = 1;
    for (i; i <= payload?.adults; i++) {
      travelers.push({
        id: i.toString(),
        travelerType: "ADULT",
      });
    }
    for (let j = 1; j <= payload?.children; j++) {
      travelers.push({
        id: i.toString(),
        travelerType: "CHILD",
      });
      i++;
    }
    for (let k = 1; k <= payload?.infants; k++) {
      travelers.push({
        id: i.toString(),
        associatedAdultId: k.toString(),
        travelerType: "HELD_INFANT",
      });
      i++;
    }

    const originDestinations: any[] = [
      {
        id: "1",
        originLocationCode: payload?.origin,
        destinationLocationCode: payload?.destination,
        departureDateTimeRange: {
          date: payload?.departureDate,
        },
      },
    ];
    if (payload?.returnDate && payload?.type == "round") {
      originDestinations?.push({
        id: "2",
        originLocationCode: payload?.destination,
        destinationLocationCode: payload?.origin,
        arrivalDateTimeRange: {
          date: payload?.returnDate,
        },
      });
    }
    let carrierRestrictions = {};
    if (payload?.includedCarrierCodes?.length > 0) {
      carrierRestrictions = {
        carrierRestrictions: {
          includedCarrierCodes: payload?.includedCarrierCodes,
        },
      };
    }
    const finalPayload = {
      currencyCode: payload?.currencyCode || "CAD",
      originDestinations: originDestinations,
      travelers: travelers,
      sources: ["GDS"],
      searchCriteria: {
        maxFlightOffers: payload?.max || 250,
        maxPrice: payload?.maxPrice,
        flightFilters: {
          maxFlightTime: payload?.maxFlightTime,
          connectionRestriction: {
            maxNumberOfConnections: payload?.nonStop
              ? 0
              : payload?.maxNumberOfConnections,
            nonStopPreferred: payload?.nonStop,
          },
          pricingOptions: {
            includedCheckedBagsOnly: payload?.includedCheckedBagsOnly,
          },
          ...carrierRestrictions,
          cabinRestrictions: [
            {
              cabin: payload?.travelClass,
              originDestinationIds: originDestinations?.map((item) => item?.id),
            },
          ],
        },
      },
    };

    const response = await fetch.Post(
      controller + "flight-offers",
      finalPayload,
      "application/json",
      token
    );
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 201) {
      const data = await response.json();
      throw new Error(data);
    } else if (response?.status != 429 && response?.status != 401) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDealFlights = async (payload: any = {}, token: string) => {
  try {
    const data: flightProps = await fetchFlightOffersByGet(payload, token);
    const price = data?.data?.map((item) => item?.price?.grandTotal);
    return price[0];
  } catch (error) {
    throw error;
  }
};

export const createOrder = async (payload: any, token: string) => {
  try {
    let travelers = [];
    let i = 1;
    const searchModel = store?.getState()?.flights?.searchModel;
    const travelerPayload = payload?.billingDetailModel;
    for (i; i <= searchModel?.adults; i++) {
      const travelerType = "adults";
      travelers.push({
        id: i.toString(),
        type: travelerType,
        dateOfBirth: travelerPayload[`${travelerType}dob${i}`],
        name: {
          firstName: travelerPayload[`${travelerType}FirstName${i}`],
          middleName: travelerPayload[`${travelerType}MiddleName${i}`],
          lastName: travelerPayload[`${travelerType}LastName${i}`],
        },
        gender: travelerPayload[`${travelerType}Gender${i}`],
        contact: {
          addresseeName: {
            firstName: travelerPayload[`${travelerType}FirstName${i}`],
            middleName: travelerPayload[`${travelerType}MiddleName${i}`],
            lastName: travelerPayload[`${travelerType}LastName${i}`],
          },
          address: {
            lines: [travelerPayload["address"]],
            countryCode: travelerPayload["countryCode"],
            cityName: travelerPayload["cityName"],
            stateName: travelerPayload["stateName"],
            postalCode: travelerPayload["pinCode"],
          },
          emailAddress: travelerPayload?.email,
          phones: [
            {
              deviceType: "MOBILE",
              countryCallingCode: "91",
              number: travelerPayload?.phoneNumber,
            },
          ],
        },
      });
    }
    for (let j = 1; j <= searchModel?.children; j++) {
      const travelerType = "children";
      travelers.push({
        id: i.toString(),
        type: travelerType,
        dateOfBirth: travelerPayload[`${travelerType}dob${j}`],
        name: {
          firstName: travelerPayload[`${travelerType}FirstName${j}`],
          middleName: travelerPayload[`${travelerType}MiddleName${j}`],
          lastName: travelerPayload[`${travelerType}LastName${j}`],
        },
        gender: travelerPayload[`${travelerType}Gender${j}`],
        contact: {
          addresseeName: {
            firstName: travelerPayload[`${travelerType}FirstName${j}`],
            middleName: travelerPayload[`${travelerType}MiddleName${j}`],
            lastName: travelerPayload[`${travelerType}LastName${j}`],
          },
          address: {
            lines: [travelerPayload["address"]],
            countryCode: travelerPayload["countryCode"],
            cityName: travelerPayload["cityName"],
            stateName: travelerPayload["stateName"],
            postalCode: travelerPayload["pinCode"],
          },
          emailAddress: travelerPayload?.email,
          phones: [
            {
              deviceType: "MOBILE",
              countryCallingCode: "91",
              number: travelerPayload?.phoneNumber,
            },
          ],
        },
      });
      i++;
    }
    for (let k = 1; k <= searchModel?.infants; k++) {
      const travelerType = "infants";
      travelers.push({
        id: i.toString(),
        type: travelerType,
        dateOfBirth: travelerPayload[`${travelerType}dob${k}`],
        name: {
          firstName: travelerPayload[`${travelerType}FirstName${k}`],
          middleName: travelerPayload[`${travelerType}MiddleName${k}`],
          lastName: travelerPayload[`${travelerType}LastName${k}`],
        },
        gender: travelerPayload[`${travelerType}Gender${k}`],
        contact: {
          addresseeName: {
            firstName: travelerPayload[`${travelerType}FirstName${k}`],
            middleName: travelerPayload[`${travelerType}MiddleName${k}`],
            lastName: travelerPayload[`${travelerType}LastName${k}`],
          },
          address: {
            lines: [travelerPayload["address"]],
            countryCode: travelerPayload["countryCode"],
            cityName: travelerPayload["cityName"],
            stateName: travelerPayload["stateName"],
            postalCode: travelerPayload["pinCode"],
          },
          emailAddress: travelerPayload?.email,
          phones: [
            {
              deviceType: "MOBILE",
              countryCallingCode: "91",
              number: travelerPayload?.phoneNumber,
            },
          ],
        },
      });
      i++;
    }
    const mailBody = createMailTemplate(travelers, payload);

    const finalPayload = { data: { ...payload?.data, travelers } };
    fetch.Post("send-mail.php", { mailBody }, "multipart/form-data", null, "/");
    const response = await fetch.Post(
      "v1/booking/flight-orders",
      finalPayload,
      "application/json",
      token
    );

    if (response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response?.status != 429 && response?.status != 401) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
