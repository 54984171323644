import { Box, Link, Stack, SxProps } from "@mui/material";
import { PrimaryButton } from "src/components/Buttons";
import { links1, links2, links3, socialMedia } from "./constants";
import Banner from "src/components/Banner";
import Logo from "src/components/LogoWhite";

const Column = ({ children, sx }: { children: any; sx?: SxProps }) => (
  <Box sx={{ width: { md: "23%", xs: "100%" }, mr: { md: 2, xs: 0 }, ...sx }}>
    {children}
  </Box>
);
const Footer = () => {
  return (
    <Banner>
      <Box sx={{ width: 200, mb: 5, ml: { md: -4, xs: 0 } }}>
        <Logo />
      </Box>
      <Stack
        direction="row"
        sx={{
          display: { md: "flex", xs: "block" },
          justifyContent: "space-between",
        }}
      >
        <Column>
          <Box component="h3" color="white">
            Quick Links
          </Box>
          <Box component="ul" sx={{ listStyle: "none", paddingLeft: 0 }}>
            {links1?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    display: "flex",
                    mb: 1,
                  }}
                >
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={item?.value}
                  >
                    {item?.label}
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Column>
        <Column sx={{ width: { md: "30%" }, mr: { md: 3, xs: 0 } }}>
          <Box component="h3" color="white">
            Terms & Conditions
          </Box>
          <Box component="p" sx={{ color: "white" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor
          </Box>
          <PrimaryButton>More info</PrimaryButton>
        </Column>
        <Column>
          <Box component="h3" color="white">
            Other Links
          </Box>
          <Box component="ul" sx={{ listStyle: "none", paddingLeft: 0 }}>
            {links2?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    display: "flex",
                    mb: 1,
                  }}
                >
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={item?.value}
                  >
                    {item?.label}
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Column>
        <Column sx={{ mr: 0 }}>
          <Box component="h3" color="white">
            More
          </Box>
          <Box component="ul" sx={{ listStyle: "none", paddingLeft: 0 }}>
            {links3?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    display: "flex",
                    mb: 1,
                  }}
                >
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={item?.value}
                  >
                    {item?.label}
                  </Link>
                </Box>
              );
            })}
          </Box>
          <Stack
            direction="row"
            spacing={1}
            component="ul"
            sx={{ listStyle: "none", paddingLeft: 0 }}
          >
            {socialMedia?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    display: "flex",
                    mb: 1,
                  }}
                >
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={item?.value}
                  >
                    {item?.icon}
                  </Link>
                </Box>
              );
            })}
          </Stack>
        </Column>
      </Stack>
    </Banner>
  );
};

export default Footer;
