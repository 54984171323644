import {
  Box,
  OutlinedInput,
  InputAdornment,
  Stack,
  useTheme,
  alpha,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel as DefaultFormLabel,
  Select,
  MenuItem,
  Link,
  SxProps,
} from "@mui/material";
import { inputs, reviewPolicy } from "./constants";
import { connect } from "react-redux";
import { flightSearchProps, reducerProps } from "src/interfaces";
import { useState } from "react";
import { PrimaryButton } from "src/components/Buttons";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { Checkbox } from "src/components";
import { formatCardNumber, formatExpiryDate } from "src/helpers";
import { createFlightOrder } from "src/store";
import MuiPhoneNumber from "material-ui-phone-number";

const FormLabel = (props: {
  children: any;
  sx?: SxProps;
  color?: "primary" | "secondary";
}) => {
  return (
    <DefaultFormLabel
      color={props?.color}
      sx={{ ...props?.sx, ".MuiFormLabel-asterisk": { color: "red" } }}
    >
      {props?.children}
    </DefaultFormLabel>
  );
};
const Component = ({
  model,
  booking,
  createOrder,
  billingDetailModel,
  setBillingDetailModel,
}: {
  model: flightSearchProps;
  booking: any;
  createOrder: (payload: any) => void;
  billingDetailModel: any;
  setBillingDetailModel: Function;
}) => {
  const theme = useTheme();

  const travellers = {
    adults: model?.adults,
    children: model?.children,
    infants: model?.infants,
  };

  const onChange = (event: any, type?: string) => {
    const name = event?.target?.name;
    let value: string = event?.target?.value;

    if (
      type === "numberOnly" ||
      type === "cardNumber" ||
      type === "expiryDate"
    ) {
      value = value?.replace(/[^0-9]/g, "");
    }
    if (type === "charOnly") {
      value = value?.replace(/[^A-Z,a-z," "]/g, "");
    }
    let newParams: any = {};
    if (name == "countryCode") {
      newParams = { stateName: "" };
    }
    setBillingDetailModel({
      ...billingDetailModel,
      [name]: value,
      ...newParams,
    });
  };
  const onSubmit = async (event) => {
    event?.preventDefault();
    createOrder({
      data: { type: "flight-order", flightOffers: [booking] },
      billingDetailModel,
    });
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      {inputs(travellers)?.map((item, index) => {
        return (
          <Box key={index} sx={{ px: { md: 0, xs: 1 } }}>
            {item?.title && (
              <Stack
                component="h3"
                spacing={1.5}
                sx={{
                  alignItems: "center",
                  ml: 1,
                  pb: 0.5,
                  borderBottom: `1px solid ${theme?.colors?.alpha?.gray[30]}`,
                }}
                direction="row"
              >
                {item?.startIcon}
                <Box
                  sx={{
                    color: `${theme?.colors?.alpha?.black[100]} !important`,
                  }}
                >
                  {item?.title}{" "}
                  {item?.required && (
                    <Box component="span" sx={{ color: "red" }}>
                      *
                    </Box>
                  )}
                  {" :"}
                </Box>
              </Stack>
            )}
            {item?.subTitle && (
              <Box sx={{ mb: 2, fontWeight: "bold", mt: 1 }}>
                {item?.subTitle}
              </Box>
            )}
            <Stack
              direction="row"
              sx={{
                display: { xs: "block", md: "flex" },
                mt: { md: item?.mt || 0 },
              }}
              spacing={{ xs: 0, md: item?.spacing || 2 }}
            >
              {item?.input?.map((input, inputIndex) => {
                if (input?.type === "phoneNumber") {
                  return (
                    <FormControl
                      key={inputIndex}
                      sx={{
                        width: { md: input?.width || "50%", xs: "100%" },
                        ".MuiFormControl-root>div::before": {
                          borderBottom: "unset !important",
                        },
                        ".MuiFormControl-root>div::after": {
                          borderBottom: "unset !important",
                        },
                        ".MuiFormControl-root::focus": {
                          border: `2px solid ${theme?.colors?.secondary?.main}`,
                        },
                        position: "relative",
                        svg: {
                          width: 20,
                          height: 20,
                          borderRadius: 20,
                        },
                        button: {
                          mr: 1,
                        },
                      }}
                      required={input?.required}
                    >
                      {input?.title && (
                        <FormLabel sx={{ mb: 0.5 }} color="secondary">
                          {input?.title}
                        </FormLabel>
                      )}
                      <Box
                        sx={{
                          position: "absolute",
                          backgroundColor: theme?.colors?.secondary?.main,
                          height: 45,
                          width: 45,
                          top: input?.title ? 25 : 0,
                        }}
                      />
                      <MuiPhoneNumber
                        {...input}
                        defaultCountry={"in"}
                        disableAreaCodes={true}
                        value={billingDetailModel[input?.key]}
                        color="secondary"
                        name={input?.key}
                        sx={{
                          mb: { xs: 2, md: 0 },
                          width: "100%",
                          p: 0,
                          pl: 0.5,
                          py: input?.startIcon ? 0 : 1.2,
                          borderRadius: 0,
                          border: `2.5px solid ${theme?.colors?.alpha?.secondary[50]}`,
                          ":before": {
                            borderBottom: "unset !important",
                          },
                          backgroundColor: alpha(
                            theme?.colors?.alpha?.gray[100],
                            0.08
                          ),
                        }}
                        inputProps={{
                          onChange: onChange,
                          placeholder: input?.placeholder,
                          maxLength: input?.maxLength,
                          minLength: input?.minLength,
                          sx: { py: 0 },
                        }}
                      />
                    </FormControl>
                  );
                }
                if (input?.type === "select") {
                  return (
                    <FormControl
                      key={inputIndex}
                      sx={{
                        width: { md: input?.width || "50%", xs: "100%" },
                      }}
                      required={input?.required}
                    >
                      {input?.title && (
                        <FormLabel sx={{ mb: 0.5 }} color="secondary">
                          {input?.title}
                        </FormLabel>
                      )}
                      <Select
                        {...input}
                        value={billingDetailModel[input?.key] || ""}
                        onChange={onChange}
                        key={inputIndex}
                        color="secondary"
                        name={input?.key}
                        sx={{
                          mb: { xs: 2, md: 0 },
                          width: "100%",
                          p: 0,
                          py: input?.startIcon ? 0 : 1.2,
                          borderRadius: 0,
                          border: `0.5px solid ${theme?.colors?.alpha?.secondary[50]}`,
                          backgroundColor: alpha(
                            theme?.colors?.alpha?.gray[100],
                            0.08
                          ),
                        }}
                        inputProps={{
                          placeholder: input?.placeholder,
                          sx: { py: 0 },
                        }}
                        startAdornment={
                          input?.startIcon ? (
                            <InputAdornment
                              sx={{
                                backgroundColor: theme?.colors?.secondary?.dark,
                                color: theme?.colors?.alpha?.white[100],
                                py: 2.5,
                                px: 1.5,
                              }}
                              position="start"
                            >
                              {input?.startIcon}
                            </InputAdornment>
                          ) : null
                        }
                      >
                        <MenuItem disabled value="">
                          <em>{input?.placeholder}</em>
                        </MenuItem>
                        {input?.dependent
                          ? input?.options[
                              billingDetailModel[input?.dependent] || ""
                            ]?.map((option, optionIndex) => (
                              <MenuItem key={optionIndex} value={option?.value}>
                                {option?.label}
                              </MenuItem>
                            ))
                          : input?.options?.map((option, optionIndex) => (
                              <MenuItem key={optionIndex} value={option?.value}>
                                {option?.label}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  );
                }

                if (input?.type === "radio") {
                  return (
                    <FormControl
                      key={inputIndex}
                      sx={{
                        width: { md: input?.width || "50%", xs: "100%" },
                      }}
                      required={input?.required}
                    >
                      {input?.title && (
                        <FormLabel color="secondary">{input?.title}</FormLabel>
                      )}
                      <RadioGroup
                        sx={{ justifyContent: "space-around" }}
                        row
                        name={input?.key}
                        value={billingDetailModel[input?.key]}
                        onChange={onChange}
                      >
                        {input?.options?.map((option, optionIndex) => (
                          <FormControlLabel
                            key={optionIndex}
                            value={option?.value}
                            control={<Radio color="secondary" />}
                            label={option?.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  );
                }
                return (
                  <FormControl
                    key={inputIndex}
                    sx={{
                      width: { md: input?.width || "50%", xs: "100%" },
                    }}
                    required={input?.required}
                  >
                    {input?.title && (
                      <FormLabel sx={{ mb: 0.5 }} color="secondary">
                        {input?.title}
                      </FormLabel>
                    )}
                    <OutlinedInput
                      {...input}
                      value={
                        input?.type === "cardNumber"
                          ? formatCardNumber(billingDetailModel[input?.key])
                          : input?.type === "expiryDate"
                          ? formatExpiryDate(billingDetailModel[input?.key])
                          : billingDetailModel[input?.key]
                      }
                      onChange={(event) => {
                        onChange(event, input?.type);
                      }}
                      color="secondary"
                      name={input?.key}
                      sx={{
                        mb: { xs: 2, md: 0 },
                        width: "100%",
                        p: 0,
                        py: input?.startIcon ? 0 : 1.2,
                        borderRadius: 0,
                        border: `0.5px solid ${theme?.colors?.alpha?.secondary[50]}`,
                        backgroundColor: alpha(
                          theme?.colors?.alpha?.gray[100],
                          0.08
                        ),
                      }}
                      inputProps={{
                        placeholder: input?.placeholder,
                        maxLength: input?.maxLength,
                        minLength: input?.minLength,
                        sx: { py: 0 },
                      }}
                      startAdornment={
                        input?.startIcon ? (
                          <InputAdornment
                            sx={{
                              backgroundColor: theme?.colors?.secondary?.dark,
                              color: theme?.colors?.alpha?.white[100],
                              py: 2.5,
                              px: 1.5,
                            }}
                            position="start"
                          >
                            {input?.startIcon}
                          </InputAdornment>
                        ) : null
                      }
                    />
                  </FormControl>
                );
              })}
            </Stack>
          </Box>
        );
      })}
      <Stack
        component="h3"
        spacing={1.5}
        sx={{
          alignItems: "center",
          ml: 1,
          pb: 0.5,
          borderBottom: `1px solid ${theme?.colors?.alpha?.gray[30]}`,
        }}
        direction="row"
      >
        <EyeIcon fontSize="small" color="secondary" />
        <Box
          sx={{
            color: `${theme?.colors?.alpha?.black[100]} !important`,
          }}
        >
          Review Policy
        </Box>
      </Stack>
      <Box component="ul">
        {reviewPolicy?.map((policy, policyIndex) => (
          <Box sx={{ mb: 0.5 }} key={policyIndex} component="li">
            {policy}
            {" - "}
            {reviewPolicy?.length - 1 === policyIndex && (
              <Link
                target="_blank"
                href="/booking-rules"
                sx={{ textDecoration: "none", color: "blue" }}
              >
                Read More
              </Link>
            )}
          </Box>
        ))}
      </Box>
      <Stack
        sx={{
          width: "100%",
          ml: "auto",
          backgroundColor: "#d0eeff",
          borderRadius: 1,
          px: 2,
          py: 1,
          my: 2,
          alignItems: "center",
        }}
        direction="row"
      >
        <Box sx={{}}>
          <FormControlLabel
            sx={{
              alignItems: "flex-start",
              ".MuiFormControlLabel-asterisk": { display: "none" },
            }}
            value={billingDetailModel["agreement"] || ""}
            required
            control={
              <Checkbox
                name="agreement"
                onChange={onChange as any}
                value={billingDetailModel["agreement"] || ""}
                size="small"
                color="secondary"
              />
            }
            label={
              <Box
                sx={{
                  mt: 0.6,
                }}
              >
                By selecting{" "}
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  Authorize & Complete Booking
                </Box>{" "}
                I acknowledge that I have read and accept the above,{" "}
                <Link
                  target="_blank"
                  href="/booking-rules"
                  sx={{ textDecoration: "none", color: "blue" }}
                >
                  Booking Rules & Restrictions
                </Link>
                ,{" "}
                <Link
                  target="_blank"
                  href="/terms-conditions"
                  sx={{ textDecoration: "none", color: "blue" }}
                >
                  Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link
                  target="_blank"
                  href="/privacy-policy"
                  sx={{ textDecoration: "none", color: "blue" }}
                >
                  Privacy Policy
                </Link>{" "}
                and I authorize the charges on my credit card.
              </Box>
            }
          />
        </Box>
      </Stack>
      <PrimaryButton
        type="submit"
        sx={{ ml: "auto", mt: { md: 2, xs: 1 }, borderRadius: 0 }}
      >
        Submit
      </PrimaryButton>
    </Box>
  );
};

const mapStateToProps = ({
  flights: { searchModel, booking },
}: reducerProps) => ({
  model: searchModel,
  booking,
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: (payload: any) => dispatch(createFlightOrder(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
