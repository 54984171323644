import { removeAuthUser, store } from "src/store";
import { fetch, firebase } from "./http";
import {
  applyActionCode,
  confirmPasswordReset,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  changePasswordProps,
  loginProps,
  resetPasswordProps,
  verifyEmailActionProps,
} from "src/interfaces";

const controller: string = "v1/security/oauth2/";

export const getAmadeusToken = async () => {
  if (
    origin === "http://localhost:3000" ||
    origin === "http://192.168.18.128:3000" ||
    origin === "http://localhost:3001"
  ) {
    const payload = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };
    try {
      const response = await fetch.Postx_www_form(
        controller + `token`,
        `grant_type=client_credentials&client_id=${payload?.client_id}&client_secret=${payload?.client_secret}`
      );
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else if (response.status === 201) {
        const data = await response.json();
        throw new Error(data);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      throw error;
    }
  } else {
    try {
      const response = await fetch.Post(
        "token.php",
        { base: process.env.REACT_APP_BASE_URL },
        "multipart/form-data",
        null,
        "/"
      );
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else if (response.status === 201) {
        const data = await response.json();
        throw new Error(data);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      throw error;
    }
  }
};
export const login = async (payload: loginProps) => {
  const email = payload.username;
  const password = payload.password;

  try {
    const userCredential = await signInWithEmailAndPassword(
      firebase.firebaseAuth,
      email,
      password
    );
    const userData: any = await firebase.GetDoc(
      "users",
      userCredential?.user?.uid
    );
    return {
      uid: userCredential?.user?.uid,
      fullName: userData?.fullName,
      role: userData?.role,
      email: userCredential?.user?.email,
      emailVerified: userCredential?.user?.emailVerified,
      isLoggedIn: true,
    };
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    await signOut(firebase.firebaseAuth);
  } catch (error) {
    throw error;
  }
};

export const validateUser = async (payload: {
  username: string;
  role: string;
}) => {
  try {
    const response = await fetch.Post(controller + "CheckIsValidUser", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      store.dispatch(removeAuthUser());
      throw "Session expired";
    }
  } catch (error) {
    throw error;
  }
};

export const resendUserVerificationEmail = async () => {
  try {
    await sendEmailVerification(firebase.firebaseAuth.currentUser);
    return "Verification email has beed resent";
  } catch (error) {
    throw error;
  }
};

export const verifyEmail = async (payload: verifyEmailActionProps) => {
  try {
    switch (payload.mode) {
      case "verifyEmail":
        await applyActionCode(firebase.firebaseAuth, payload.actionCode);
        return "User verified";
      case "resetPassword":
        await verifyPasswordResetCode(
          firebase.firebaseAuth,
          payload.actionCode
        );
        return "Set new password";
      default:
    }
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(firebase.firebaseAuth, email);
    return "Password reset link sent to " + email;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (payload: resetPasswordProps) => {
  try {
    await confirmPasswordReset(
      firebase.firebaseAuth,
      payload.actionCode,
      payload.password
    );
    return "Password reset success";
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (payload: changePasswordProps) => {
  try {
    await updatePassword(firebase.firebaseAuth.currentUser, payload.password);
    return "Password change success";
  } catch (error) {
    throw error;
  }
};
