import { Box, Slider as DefaultSlider, SliderProps } from "@mui/material";

const Slider = (props: SliderProps) => {
  function valuetext(value: number) {
    return `${value}°C`;
  }
  const isMobile = document?.body?.clientWidth <= 991;
  return (
    <Box sx={{ position: "relative", width: "100%", mt: { md: 1, xs: 2 } }}>
      <DefaultSlider
        size="small"
        color="secondary"
        getAriaLabel={valuetext}
        components={{
          Track: () => (
            <Box
              component="img"
              src="/assets/img/slider-track.svg"
              sx={{
                position: "absolute",
                width: "100%",
              }}
            />
          ),
        }}
        valueLabelDisplay={isMobile ? "on" : "auto"}
        {...props}
      />
    </Box>
  );
};

export default Slider;
