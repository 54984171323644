import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_BOOKINGS } from "./types";
import { adminReducer } from "src/interfaces";

const initialeState: adminReducer = {
  bookings: [],
};
export const admin = (
  state = initialeState,
  action: PayloadAction<any> = null
): adminReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_BOOKINGS: {
      return { ...state, bookings: payload };
    }
    default: {
      return state;
    }
  }
};
