import { firebaseDB, firebaseAuth } from "src/config";
import {
  collection,
  setDoc,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  where,
  WhereFilterOp,
  query,
} from "firebase/firestore";

export const GetDoc = async (collectionName, uid) => {
  try {
    const docRef = doc(firebaseDB, collectionName, uid);
    const docSnap = (await getDoc(docRef)).data();
    return docSnap;
  } catch (error) {
    throw error;
  }
};

export const GetDocs = async (
  collectionName,
  fieldPath = "",
  operator: WhereFilterOp = null,
  value = ""
) => {
  try {
    const collectionRef = collection(firebaseDB, collectionName);
    let queryRef: any = collectionRef;
    if (fieldPath && value && operator) {
      queryRef = query(collectionRef, where(fieldPath, operator, value));
    }
    const docsSnap = (await getDocs(queryRef)).docs.map((item) => item.data());

    return docsSnap;
  } catch (error) {
    throw error;
  }
};

export const AddDocWithCustomDocId = async (collectionName, docId, payload) => {
  const docRef = doc(firebaseDB, collectionName, docId);
  const docSnap = await setDoc(docRef, payload);

  return docSnap;
};

export const AddDoc = async (collectionName, payload) => {
  const docRef = doc(firebaseDB, collectionName);
  const docSnap = await setDoc(docRef, payload);

  return docSnap;
};

export const UpdateDoc = async (collectionName, payload, documentId) => {
  const docRef = doc(firebaseDB, collectionName, documentId);
  const docSnap = await updateDoc(docRef, payload);
  return docSnap;
};

export const DeleteDoc = async (collectionName, uid) => {
  const docRef = doc(firebaseDB, collectionName, uid);
  const docSnap = await deleteDoc(docRef);

  return docSnap;
};
const firebase = {
  GetDoc,
  GetDocs,
  AddDocWithCustomDocId,
  AddDoc,
  UpdateDoc,
  DeleteDoc,
  firebaseAuth,
};
export default firebase;
