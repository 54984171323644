import { useContext } from "react";

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
} from "@mui/material";
import { SidebarContext } from "src/contexts/SidebarContext";

import Logo from "src/components/LogoWhite";
import { useLocation } from "react-router";

function Header() {
  const location = useLocation();
  const pathname = location?.pathname;
  const headerBackground =
    pathname === "/"
      ? "background-color:transparent"
      : `background-image:url("/assets/img/search-header-bg-2.png");background-size:cover`;
  const HeaderWrapper = styled(Box)(
    ({ theme }) => `
          color: ${theme?.user?.header.textColor};
          padding: ${theme.spacing(2, 2)};
          right: 0;
          z-index: 6;
          ${headerBackground};
          // backdrop-filter: blur(3px);
          position: ${pathname === "/" ? "absolute" : "relative"};
          justify-content: space-between;
          width: 100%;
          // @media (min-width: ${theme.breakpoints.values.lg}px) {
          //     left: ${theme?.user?.sidebar.width};
          //     width: auto;
          // }
  `
  );
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        height: theme?.user?.header?.height,
        boxShadow:
          pathname === "/"
            ? "unset"
            : theme.palette.mode === "dark"
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.secondary[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.secondary[100],
                0.1
              )}`,
      }}
    >
      <Box display="flex" alignItems="center" sx={{}}>
        <Logo />
      </Box>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <Tooltip arrow title="Toggle Menu">
          <IconButton color="primary" onClick={toggleSidebar}>
            <Box
              sx={{ width: 25 }}
              component="img"
              src="/assets/icons/menu.svg"
            />
          </IconButton>
        </Tooltip>
      </Stack>
    </HeaderWrapper>
  );
}

export default Header;
