import { hoursToMinutes } from "date-fns";
import moment from "moment";
import {
  timeLabel,
  timeDifference,
  addTax,
  addTaxByTraveler,
} from "src/helpers";
import {
  Price2,
  flightDataProps,
  flightSearchProps,
  itineraryProps,
  segmentProps,
} from "src/interfaces";
import { getCanadaToCanada } from "../helpers";

export const travellersPriceDetailHandler = (
  item: flightDataProps,
  isCanadaToCanada?: boolean
) => {
  let travelerPricingData: any = {};

  let travelerPricing = item?.travelerPricings;
  travelerPricing?.map((item) => {
    let newTravelerPricing: Price2 = item?.price;
    if (isCanadaToCanada) {
      newTravelerPricing = addTaxByTraveler(item?.price);
    }
    const t = newTravelerPricing?.total;
    const b = newTravelerPricing?.base;

    travelerPricingData = {
      ...travelerPricingData,
      [item?.travelerType]: {
        ...newTravelerPricing,
        fees: (parseFloat(t) - parseFloat(b))?.toFixed(2),
      },
    };
  });
  return travelerPricingData;
};

export const stopOptions = [
  {
    label: "Nonstop",
    value: 0,
  },
  {
    label: "1 Stop Max",
    value: 1,
  },
  {
    label: "Any",
    value: 2,
  },
];

export const filterDataExtractor = (items: flightDataProps[], dictionaries) => {
  let maxDuration: number = 0;
  let minDuration: number = 0;
  let maxDeparture: any = "";
  let minDeparture: any = "";
  let maxLayover: number = 0;
  let carrierCodes = [];
  let dCodes: string[] = [];
  let aCodes: string[] = [];
  let cheapestPrice = "";
  let quickestPrice = "";
  let bestPrice = "";
  items?.map((item) => {
    let grandTotal = item?.price?.grandTotal;

    item?.itineraries?.map((itinerary, itineraryIndex) => {
      const firstSegment = itinerary?.segments[0];

      const isCanadaToCanada = getCanadaToCanada(
        itinerary?.segments,
        dictionaries?.locations
      );

      if (isCanadaToCanada) {
        grandTotal = addTax(item?.price)?.grandTotal;
      }
      // price start //
      if (!cheapestPrice) {
        cheapestPrice = grandTotal;
      } else {
        if (parseFloat(cheapestPrice as any) > parseFloat(grandTotal as any)) {
          cheapestPrice = grandTotal;
        }
      }
      // price end //

      // min, max duration start //
      let duration = itinerary?.duration?.replace("PT", "");
      if (duration?.includes("H")) {
        duration = duration?.replace("H", ":");
      } else {
        duration = "00:" + duration;
      }
      if (duration?.includes("M")) {
        duration = duration?.replace("M", "");
      }

      duration = !duration?.split(":")[1]?.trim() ? duration + "00" : duration;

      const minutes =
        hoursToMinutes(parseInt(duration?.split(":")[0])) +
        parseInt(duration?.split(":")[1]);

      let layover = minutes;

      if (maxDuration) {
        const isTrue = minutes > maxDuration;
        if (isTrue) {
          maxDuration = minutes;
        }
      } else {
        maxDuration = minutes;
      }

      if (minDuration) {
        const isTrue = minutes < minDuration;
        if (isTrue) {
          minDuration = minutes;
          quickestPrice = grandTotal;
          if (!bestPrice) {
            bestPrice = grandTotal;
          } else {
            if (bestPrice > grandTotal) {
              bestPrice = grandTotal;
            }
          }
        }
      } else {
        minDuration = minutes;
        quickestPrice = grandTotal;
        if (!bestPrice) {
          bestPrice = grandTotal;
        } else {
          if (bestPrice > grandTotal) {
            bestPrice = grandTotal;
          }
        }
      }
      // min, max duration end //

      // min, max departure start //

      const carrierCode = firstSegment?.carrierCode;

      if (!carrierCodes?.includes(carrierCode)) {
        carrierCodes?.push(carrierCode);
      }

      const departureTime = moment(firstSegment["departure"]?.at)?.format(
        "HH:mm"
      );

      if (maxDeparture) {
        const isTrue = timeDifference(departureTime, maxDeparture);
        if (isTrue) {
          maxDeparture = departureTime;
        }
      } else {
        maxDeparture = departureTime;
      }
      if (minDeparture) {
        const isTrue = timeDifference(minDeparture, departureTime);
        if (isTrue) {
          minDeparture = departureTime;
        }
      } else {
        minDeparture = departureTime;
      }
      // min, max departure end //

      itinerary?.segments?.map((segment, segmentIndex) => {
        // departure, arrival cities code start //
        const dCode = segment["departure"]?.iataCode;
        if (!dCodes.includes(dCode)) {
          dCodes.push(dCode);
        }
        const aCode = segment["arrival"]?.iataCode;
        if (!aCodes.includes(aCode)) {
          aCodes.push(aCode);
        }
        // departure, arrival cities code end //

        // max layover start //

        let segmentDuration = segment?.duration?.replace("PT", "");
        if (segmentDuration?.includes("H")) {
          segmentDuration = segmentDuration?.replace("H", ":");
        } else {
          segmentDuration = "00:" + segmentDuration;
        }
        if (segmentDuration?.includes("M")) {
          segmentDuration = segmentDuration?.replace("M", "");
        }

        segmentDuration = !segmentDuration?.split(":")[1]?.trim()
          ? segmentDuration + "00"
          : segmentDuration;

        const segmentMinutes =
          hoursToMinutes(parseInt(segmentDuration?.split(":")[0])) +
          parseInt(segmentDuration?.split(":")[1]);
        layover -= segmentMinutes as number;
      });
      if (layover > maxLayover) {
        maxLayover = layover;
      }
    });
    // max layover end //
  });
  return {
    departure: dCodes,
    arrival: aCodes,
    initialDepartureCarriers: carrierCodes,
    minDeparture,
    maxDeparture,
    minDuration,
    maxDuration,
    maxLayover,
    cheapestPrice,
    quickestPrice,
    bestPrice,
  };
};
interface filterProps extends flightSearchProps {
  minOutboundTime?: string;
  duration?: number;
}
export const filterListData = (list: any, filters: filterProps) => {
  try {
    var newData: any[] = [];
    const items: any[] = list?.data;
    items?.map((item) => {
      let isPreviousTrue = false;
      const startItinary: itineraryProps = item?.itineraries[0];
      let duration = startItinary?.duration?.replace("PT", "");
      if (duration?.includes("H")) {
        duration = duration?.replace("H", ":");
      } else {
        duration = "00:" + duration;
      }
      if (duration?.includes("M")) {
        duration = duration?.replace("M", "");
      }
      duration = !duration?.split(":")[1]?.trim() ? duration + "00" : duration;
      const minutes =
        hoursToMinutes(parseInt(duration?.split(":")[0])) +
        parseInt(duration?.split(":")[1]);
      isPreviousTrue = minutes <= filters?.duration;

      if (isPreviousTrue === true && item?.itineraries?.length > 1) {
        const returnItinary = item?.itineraries[1];
        let returnDuration = returnItinary?.duration?.replace("PT", "");
        if (returnDuration?.includes("H")) {
          returnDuration = returnDuration?.replace("H", ":");
        } else {
          returnDuration = "00:" + returnDuration;
        }
        if (returnDuration?.includes("M")) {
          returnDuration = returnDuration?.replace("M", "");
        }
        returnDuration = !returnDuration?.split(":")[1]?.trim()
          ? returnDuration + "00"
          : returnDuration;
        const returnMinutes =
          hoursToMinutes(parseInt(returnDuration?.split(":")[0])) +
          parseInt(returnDuration?.split(":")[1]);
        isPreviousTrue = returnMinutes <= filters?.duration;
      }

      const firstSegment: segmentProps = startItinary?.segments[0];
      const lastSegment: segmentProps =
        startItinary?.segments[startItinary?.segments?.length - 1];
      if (isPreviousTrue) {
        const departureTime = moment(firstSegment["departure"]?.at)?.format(
          "HH:mm"
        );
        const minDepartureTime = timeLabel(
          filters?.outbound[0],
          filters?.minOutboundTime
        );
        const maxDepartureTime = timeLabel(
          filters?.outbound[1],
          filters?.minOutboundTime
        );
        const minDiff = timeDifference(
          departureTime,
          minDepartureTime,
          "boolean",
          true
        );
        const maxDiff = timeDifference(
          maxDepartureTime,
          departureTime,
          "boolean",
          true
        );
        isPreviousTrue = minDiff === true && maxDiff === true;
      }
      if (isPreviousTrue) {
        let layover = minutes;
        startItinary?.segments?.map((segment, segmentIndex) => {
          // max layover start //

          let segmentDuration = segment?.duration?.replace("PT", "");
          if (segmentDuration?.includes("H")) {
            segmentDuration = segmentDuration?.replace("H", ":");
          } else {
            segmentDuration = "00:" + segmentDuration;
          }
          if (segmentDuration?.includes("M")) {
            segmentDuration = segmentDuration?.replace("M", "");
          }

          segmentDuration = !segmentDuration?.split(":")[1]?.trim()
            ? segmentDuration + "00"
            : segmentDuration;

          const segmentMinutes =
            hoursToMinutes(parseInt(segmentDuration?.split(":")[0])) +
            parseInt(segmentDuration?.split(":")[1]);
          layover -= segmentMinutes as number;
        });

        isPreviousTrue =
          layover >= filters?.layover[0] && layover <= filters?.layover[1];
      }

      // departing from
      if (isPreviousTrue && filters?.departingAirports?.length > 0) {
        isPreviousTrue = filters?.departingAirports.includes(
          firstSegment?.departure?.iataCode
        );
      }

      // arriving at
      if (isPreviousTrue && filters?.arrivingAirports?.length > 0) {
        isPreviousTrue = filters?.arrivingAirports.includes(
          lastSegment?.arrival?.iataCode
        );
      }
      
      if (isPreviousTrue) {
        newData?.push(item);
      }
    });
    const newList = { ...list, meta: newData?.length, data: newData };
    return newList;
  } catch {
    return list;
  }
};

export const sortingDataExtractor = (
  items: flightDataProps[],
  dictionaries
) => {
  let minDuration: number = 0;
  let cheapestPrice = 0;
  let quickestPrice = 0;
  let bestPrice = 0;
  items?.map((item) => {
    let grandTotal = parseFloat(item?.price?.grandTotal);

    item?.itineraries?.map((itinerary, itineraryIndex) => {
      const isCanadaToCanada = getCanadaToCanada(
        itinerary?.segments,
        dictionaries?.locations
      );

      if (isCanadaToCanada) {
        grandTotal = parseFloat(addTax(item?.price)?.grandTotal);
      }
      // price start //
      if (!cheapestPrice) {
        cheapestPrice = grandTotal;
      } else {
        if (parseFloat(cheapestPrice as any) > parseFloat(grandTotal as any)) {
          cheapestPrice = grandTotal;
        }
      }
      // price end //

      // min, max duration start //
      let duration = itinerary?.duration?.replace("PT", "");
      if (duration?.includes("H")) {
        duration = duration?.replace("H", ":");
      } else {
        duration = "00:" + duration;
      }
      if (duration?.includes("M")) {
        duration = duration?.replace("M", "");
      }

      duration = !duration?.split(":")[1]?.trim() ? duration + "00" : duration;

      const minutes =
        hoursToMinutes(parseInt(duration?.split(":")[0])) +
        parseInt(duration?.split(":")[1]);

      if (minDuration) {
        const isTrue = minutes < minDuration;
        if (isTrue) {
          minDuration = minutes;
          quickestPrice = grandTotal;
          if (!bestPrice) {
            bestPrice = grandTotal;
          } else {
            if (bestPrice > grandTotal) {
              bestPrice = grandTotal;
            }
          }
        }
      } else {
        minDuration = minutes;
        quickestPrice = grandTotal;
        if (!bestPrice) {
          bestPrice = grandTotal;
        } else {
          if (bestPrice > grandTotal) {
            bestPrice = grandTotal;
          }
        }
      }
      // min, max duration end //
    });
  });
  return {
    cheapestPrice,
    quickestPrice,
    bestPrice,
  };
};

export const sortByDuration = (a: flightDataProps, b: flightDataProps) => {
  const aItinary = a?.itineraries[0];
  let aDuration = aItinary?.duration?.replace("PT", "");
  if (aDuration?.includes("H")) {
    aDuration = aDuration?.replace("H", ":");
  } else {
    aDuration = "00:" + aDuration;
  }
  if (aDuration?.includes("M")) {
    aDuration = aDuration?.replace("M", "");
  }
  aDuration = !aDuration?.split(":")[1]?.trim() ? aDuration + "00" : aDuration;
  const aMinutes =
    hoursToMinutes(parseInt(aDuration?.split(":")[0])) +
    parseInt(aDuration?.split(":")[1]);

  const bItinary = b?.itineraries[0];
  let bDuration = bItinary?.duration?.replace("PT", "");
  if (bDuration?.includes("H")) {
    bDuration = bDuration?.replace("H", ":");
  } else {
    bDuration = "00:" + bDuration;
  }
  if (bDuration?.includes("M")) {
    bDuration = bDuration?.replace("M", "");
  }
  bDuration = !bDuration?.split(":")[1]?.trim() ? bDuration + "00" : bDuration;
  const bMinutes =
    hoursToMinutes(parseInt(bDuration?.split(":")[0])) +
    parseInt(bDuration?.split(":")[1]);

  return aMinutes - bMinutes;
};

export const sortByPrice = (a: flightDataProps, b: flightDataProps) => {
  const aPrice = a?.price?.grandTotal;
  const bPrice = b?.price?.grandTotal;
  return parseFloat(aPrice) - parseFloat(bPrice);
};

export const sortByPriceAndDuration = (
  a: flightDataProps,
  b: flightDataProps
) => {
  const aPrice = a?.price?.grandTotal;
  const bPrice = b?.price?.grandTotal;

  const aItinary = a?.itineraries[0];
  let aDuration = aItinary?.duration?.replace("PT", "");
  if (aDuration?.includes("H")) {
    aDuration = aDuration?.replace("H", ":");
  } else {
    aDuration = "00:" + aDuration;
  }
  if (aDuration?.includes("M")) {
    aDuration = aDuration?.replace("M", "");
  }
  aDuration = !aDuration?.split(":")[1]?.trim() ? aDuration + "00" : aDuration;
  const aMinutes =
    hoursToMinutes(parseInt(aDuration?.split(":")[0])) +
    parseInt(aDuration?.split(":")[1]);

  const bItinary = b?.itineraries[0];
  let bDuration = bItinary?.duration?.replace("PT", "");
  if (bDuration?.includes("H")) {
    bDuration = bDuration?.replace("H", ":");
  } else {
    bDuration = "00:" + bDuration;
  }
  if (bDuration?.includes("M")) {
    bDuration = bDuration?.replace("M", "");
  }
  bDuration = !bDuration?.split(":")[1]?.trim() ? bDuration + "00" : bDuration;
  const bMinutes =
    hoursToMinutes(parseInt(bDuration?.split(":")[0])) +
    parseInt(bDuration?.split(":")[1]);

  return parseFloat(aPrice) - parseFloat(bPrice) || aMinutes - bMinutes;
};
