import { Box, Stack, SxProps, useTheme } from "@mui/material";
import { connect } from "react-redux";
import { addTax } from "src/helpers";
import {
  flightPriceProps,
  flightSearchProps,
  reducerProps,
} from "src/interfaces";

const travelersTitle = {
  ADULT: "Adults",
  CHILD: "Children",
  HELD_INFANT: "Infants",
};

const Component = ({
  travelerPricingData,
  priceData,
  isCanadaToCanada,
  containerSx,
  lineSx,
  model,
}: {
  travelerPricingData: any;
  priceData: flightPriceProps;
  isCanadaToCanada: boolean;
  containerSx?: SxProps;
  lineSx?: SxProps;
  model: flightSearchProps;
}) => {
  const theme = useTheme();
  const travelers = Object.keys(travelerPricingData);

  const travelerCounts = {
    ADULT: model?.adults,
    CHILD: model?.children,
    HELD_INFANT: model?.infants,
  };
  return (
    <Box
      sx={{
        position: "relative",
        width: 300,
        p: 0.5,
        ...containerSx,
      }}
    >
      <Stack direction="row" width="100%" mb={1} spacing={3}>
        {["", "Base Fare", "Taxes & Fees", "Total"]?.map(
          (priceTitle, priceTitleIndex) => (
            <Box key={priceTitleIndex} sx={{ fontSize: 12, width: "25%" }}>
              {priceTitle}
            </Box>
          )
        )}
      </Stack>
      {travelers?.map((traveler, travelerIndex) => (
        <Stack key={travelerIndex} direction="row" width="100%" spacing={3}>
          {["", "base", "fees", "total"]?.map((priceItem, priceIndex) => {
            return (
              <Box key={priceIndex} sx={{ fontSize: 12, width: "25%" }}>
                {priceIndex === 0
                  ? `${travelerCounts[traveler] || ""} ${
                      travelersTitle[traveler]
                    }`
                  : (
                      parseFloat(travelerPricingData[traveler][priceItem]) *
                      parseFloat(travelerCounts[traveler])
                    )?.toFixed(2)}
              </Box>
            );
          })}
        </Stack>
      ))}
      <Box
        sx={{
          width: 280,
          my: 0.5,
          height: 2,
          backgroundColor: theme?.colors?.info?.main,
          ...lineSx,
        }}
      />
      <Stack direction="row" width="100%" mb={1} spacing={3}>
        {["Total", "base", "fees", "grandTotal"]?.map(
          (priceKey, priceKeyIndex) => {
            let newPriceData = priceData;
            if (isCanadaToCanada) {
              newPriceData = addTax(priceData);
            }
            let t: any = newPriceData?.grandTotal;
            let b: any = newPriceData?.base;

            let taxFees = parseFloat(t) - parseFloat(b);

            return (
              <Box key={priceKeyIndex} sx={{ fontSize: 12, width: "25%" }}>
                {priceKeyIndex === 0
                  ? priceKey
                  : priceKey === "fees"
                  ? taxFees?.toFixed(2)
                  : newPriceData[priceKey]}
              </Box>
            );
          }
        )}
      </Stack>
    </Box>
  );
};

const mapStateToProps = ({ flights: { searchModel } }: reducerProps) => ({
  model: searchModel,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
