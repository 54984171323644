import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import moment from "moment";
import airlines from "src/common/airlines.json";
import { Column, Stops } from "../../flightsList/components";
import { PrimaryButton } from "src/components/Buttons";
import FlightIcon from "@mui/icons-material/Flight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  cityAirportDictionaries,
  dictionariesProps,
  flightDataProps,
} from "src/interfaces";
import Airline from "../../components/Airline";
import { getAirportName, getCarriers } from "../../helpers";

const Component = ({
  flightData,
  dictionaries,
  cityAirportDictionaries,
  oneway = false,
  onShowDetailClick = () => {},
  showDetail = false,
}: {
  flightData: flightDataProps;
  dictionaries: dictionariesProps;
  cityAirportDictionaries: cityAirportDictionaries;
  oneway?: boolean;
  onShowDetailClick: () => void;
  showDetail: boolean;
}) => {
  const theme = useTheme();
  const itinerariesLength = flightData?.itineraries?.length;
  const carrierCodes = getCarriers(flightData?.itineraries) || [];
  return (
    <>
      {flightData?.itineraries?.map((itinerary, itineraryIndex) => {
        let duration: string = itinerary?.duration?.replace("PT", "");
        if (duration?.includes("H")) {
          duration = duration?.replace("H", " hr : ");
        } else {
          duration = "00 hr : " + duration;
        }
        if (duration?.includes("M")) {
          duration = duration?.replace("M", " min");
        }
        duration = !duration?.split(":")[1]?.trim()
          ? duration + " 00 min"
          : duration;

        const startSegment = itinerary?.segments[0];
        const lastSegment =
          itinerary?.segments[itinerary?.segments?.length - 1];
        const logo = airlines?.find(
          (ele) => ele?.id == startSegment?.carrierCode
        )?.logo;

        const departureDate = startSegment?.departure?.at;
        const arrivalDate = lastSegment?.arrival?.at;
        const stops = itinerary?.segments?.length - 1;

        const departureAirport = getAirportName(
          cityAirportDictionaries,
          startSegment?.departure?.iataCode
        )?.fullName;

        const arrivalAirport = getAirportName(
          cityAirportDictionaries,
          lastSegment?.arrival?.iataCode
        )?.fullName;

        const showDetailBtnText = showDetail ? "Minimize" : "Show Details";
        const showDetailBtnIcon = showDetail ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        );

        const stopLabel = `${stops || "Non"} ${stops > 1 ? "Stops" : "Stop"}`;

        return (
          <Box
            sx={{
              width: "100%",
              px: { md: 2, xs: 1 },
              display: "flex",
              flexDirection: "column",
            }}
          >
            {itineraryIndex === 0 && (
              <>
                <Stack
                  component="h3"
                  spacing={1.5}
                  sx={{
                    alignItems: "center",
                    ml: 1,
                    pb: 0.5,
                    mb: { md: 3, xs: 1 },
                    borderBottom: `1px solid ${theme?.colors?.alpha?.gray[30]}`,
                  }}
                  direction="row"
                >
                  <FlightIcon
                    fontSize="medium"
                    color="secondary"
                    sx={{
                      transform:
                        itineraryIndex === 0
                          ? "rotate(90deg)"
                          : "rotate(-90deg)",
                    }}
                  />
                  <Box>Review Your Booking Details:</Box>
                </Stack>
                <Stack
                  component="h4"
                  spacing={{ md: 1, xs: 0 }}
                  sx={{
                    alignItems: { md: "flex-end", xs: "center" },
                    ml: 1,
                    pb: 0.5,
                  }}
                  direction="row"
                >
                  <Box sx={{ width: "80%" }}>
                    {departureAirport} to {arrivalAirport}
                  </Box>
                  {!oneway && (
                    <Box
                      sx={{
                        fontSize: 12,
                        color: theme?.colors?.alpha?.gray[70],
                      }}
                    >
                      {" "}
                      (Round trip)
                    </Box>
                  )}
                </Stack>
              </>
            )}
            <Box
              key={itineraryIndex}
              sx={{
                borderBottom: {
                  xs:
                    itinerariesLength > 1 &&
                    itineraryIndex != itinerariesLength - 1
                      ? `1px dashed ${theme?.colors?.alpha?.gray[50]}`
                      : "unset",
                  md: "unset",
                },
                my: {
                  xs:
                    itinerariesLength > 1 &&
                    itineraryIndex != itinerariesLength - 1
                      ? 1
                      : "unset",
                  md: "unset",
                },
                width: { md: "90%", xs: "100%" },
                display: "flex",
                flexDirection: "column",
              }}
            >
              {itineraryIndex === 0 && (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    p: 1,
                    px: 1.5,
                    backgroundColor: theme?.colors?.alpha?.secondary[10],
                    borderLeft: `2px solid ${theme?.colors?.error?.main}`,
                  }}
                >
                  <ArrowRightIcon
                    color="error"
                    sx={{ position: "absolute", left: -10, top: "18%" }}
                  />
                  {carrierCodes
                    ?.map((item) => dictionaries?.carriers[item])
                    ?.join(", ")}
                </Box>
              )}
              <Stack
                direction="row"
                key={itineraryIndex}
                sx={{
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Airline
                  logo={logo}
                  iconSx={{
                    width: 25,
                    ml: { xs: 1, md: 0 },
                  }}
                />
                <Column
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    px: { md: 2, xs: 0.5 },
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box
                      component="h5"
                      sx={{ color: "black", mt: 0.5, mb: 0.5 }}
                    >
                      {startSegment?.departure?.iataCode}
                    </Box>
                    <Box
                      sx={{
                        width: { md: "25%", xs: "15%" },
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: 2,
                          backgroundColor: "gray",
                        }}
                      />
                      <Box
                        sx={{
                          mb: 0.7,
                        }}
                      >
                        <Stops
                          stops={stops}
                          spacing={7}
                          sx={{
                            width: 7,
                            minWidth: 7,
                            height: 7,
                            minHeight: 7,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          textAlign: "center",
                          mt: { md: 1 },
                          fontSize: { md: "unset", xs: 11 },
                        }}
                      >
                        {stopLabel}
                      </Box>
                    </Box>
                    <Box
                      component="h5"
                      sx={{ color: "black", mt: 0.5, mb: 0.5 }}
                    >
                      {lastSegment?.arrival?.iataCode}
                    </Box>
                    <Box sx={{ fontSize: { md: "unset", xs: 12 } }}>
                      <Box
                        component="h4"
                        sx={{ color: theme?.colors?.secondary?.main, mb: 0.5 }}
                      >
                        Departure
                      </Box>
                      <Box sx={{ color: "gray" }}>
                        {moment(departureDate as any).format("MM/DD/YYYY")},
                        {moment(departureDate as any).format("HH:mm")}
                      </Box>
                    </Box>
                    <Box sx={{ fontSize: { md: "unset", xs: 12 } }}>
                      <Box
                        component="h4"
                        sx={{ color: theme?.colors?.secondary?.main, mb: 0.5 }}
                      >
                        Arrival
                      </Box>
                      <Box sx={{ color: "gray" }}>
                        {moment(arrivalDate as any).format("MM/DD/YYYY")},
                        {moment(arrivalDate as any).format("HH:mm")}
                      </Box>
                    </Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ display: { md: "flex", xs: "none" } }}
                    >
                      {duration}
                    </Stack>
                  </Stack>
                </Column>
              </Stack>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                ml: 0.5,
                mr: 0.5,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  display: { md: "none" },
                }}
              >
                {duration}
              </Stack>
              {itinerariesLength - 1 === itineraryIndex && (
                <PrimaryButton
                  onClick={onShowDetailClick}
                  variant="text"
                  sx={{
                    textTransform: "capitalize",
                    px: 1,
                    py: 0.7,
                    backgroundColor: theme?.colors?.alpha?.white[0],
                    color: theme?.colors?.error.main,
                    fontSize: 14,
                    ml: "auto",
                  }}
                  color="error"
                >
                  {showDetailBtnText}
                  {showDetailBtnIcon}
                </PrimaryButton>
              )}
            </Stack>
          </Box>
        );
      })}
    </>
  );
};

export default Component;
