import { convertToForm } from "src/helpers";
import { store } from "src/store";

const baseUrl = process.env.REACT_APP_BASE_URL + "/";

export const Get = (url, token = store?.getState()?.auth?.access_token) => {
  return fetch(baseUrl + url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const Post = (
  url,
  payload = {},
  payloadType: "application/json" | "multipart/form-data" = "application/json",
  token = store?.getState()?.auth?.access_token,
  cBaseUrl = baseUrl
) => {
  if (payloadType === "multipart/form-data") {
    return fetch(cBaseUrl + url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: convertToForm(payload),
    });
  }
  return fetch(cBaseUrl + url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  });
};

export const Postx_www_form = (url, payload = {}) => {
  return fetch(baseUrl + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload as any,
  });
};

export const Patch = (
  url,
  payload = {},
  token = store?.getState()?.auth?.access_token
) => {
  return fetch(baseUrl + url, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  });
};

export const Delete = (
  url,
  payload = {},
  token = store?.getState()?.auth?.access_token
) => {
  return fetch(baseUrl + url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  });
};

const http = { Get, Post, Postx_www_form, Patch, Delete };
export default http;
