import { call, put, takeLatest } from "redux-saga/effects";
import { HANDLE_LOAD_BOOKINGS } from "./types";
import { loadBookings } from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setBookings } from "./actions";

function* handleLoadBookings(): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(loadBookings);
    yield put(setBookings(responseData));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* adminSaga() {
  yield takeLatest<any>(HANDLE_LOAD_BOOKINGS, handleLoadBookings);
}
