import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CardIcon from "@mui/icons-material/CreditCard";
import LocationIcon from "@mui/icons-material/LocationOn";

const travellerTitleKey = {
  adults: "Adult",
  children: "Children",
  infants: "Infant",
};

export const inputs = (travellers) => {
  const travellersInput = [];
  Object?.keys(travellers)?.map((item, index) => {
    for (let i = 1; i <= travellers[item]; i++) {
      travellersInput?.push(
        {
          title: index === 0 && i === 1 ? "Personal Details" : "",
          required: true,
          spacing: 0.5,
          startIcon: <PersonIcon fontSize="small" color="secondary" />,
          subTitle: `# ${travellerTitleKey[item]} ${i}`,
          input: [
            {
              key: `${item}FirstName${i}`,
              title: "First Name",
              placeholder: "",
              required: true,
            },
            {
              key: `${item}MiddleName${i}`,
              title: "Middle Name",
              placeholder: "",
            },
            {
              key: `${item}LastName${i}`,
              title: "Last Name",
              placeholder: "",
              required: true,
            },
          ],
        },
        {
          title: "",
          subTitle: "",
          mt: 2,
          input: [
            {
              key: `${item}Gender${i}`,
              title: "Gender",
              placeholder: "",
              type: "radio",
              options: [
                { label: "Male", value: "MALE" },
                { label: "Female", value: "FEMALE" },
                { label: "Transgender", value: "TRANSGENDER" },
              ],
              required: true,
            },
            {
              key: `${item}dob${i}`,
              title: "Date of Birth",
              type: "date",
              placeholder: "",
              required: true,
            },
          ],
        }
      );
    }
  });
  return [
    {
      title: "Contact Details",
      required: true,
      startIcon: <PhoneIcon fontSize="small" color="secondary" />,
      input: [
        {
          key: "phoneNumber",
          placeholder: "xxxxx xxxxx",
          type: "phoneNumber",
          maxLength: 20,
          // startIcon: <PhoneIcon />,
          required: true,
        },
        {
          key: "email",
          placeholder: "xyz@mail.com",
          startIcon: <EmailIcon />,
          type: "email",
          required: true,
        },
      ],
    },
    ...travellersInput,
    {
      title: "Payment Details",
      required: true,
      startIcon: <CardIcon fontSize="small" color="secondary" />,
      input: [
        {
          key: "cardNumber",
          required: true,
          type: "cardNumber",
          maxLength: 20,
          minLength: 20,
          title: "Card Number",
          placeholder: "xxxx-xxxx-xxxx-xxxx",
          width: "55%",
        },
        {
          key: "nameOnCard",
          type: "charOnly",
          maxLength: 30,
          required: true,
          title: "Name on card",
          placeholder: "",
          width: "45%",
        },
      ],
    },
    {
      title: "",
      mt: 2,
      input: [
        {
          key: "dateOfExpiry",
          required: true,
          title: "Date of Expiry",
          type: "expiryDate",
          maxLength: 7,
          minLength: 7,
          placeholder: "MM/YYYY",
          width: "30%",
        },
        {
          key: "cvv",
          required: true,
          title: "CVV",
          type: "numberOnly",
          placeholder: "CVV",
          width: "15%",
          maxLength: 4,
          minLength: 3,
        },
      ],
    },
    {
      title: "Billing Details",
      required: true,
      startIcon: <LocationIcon fontSize="small" color="secondary" />,
      input: [
        {
          key: "billingPhone",
          type: "phoneNumber",
          required: true,
          title: "Phone no.",
          maxLength: 20,
        },
        {
          key: "address",
          required: true,
          title: "Address",
        },
      ],
    },
    {
      title: "",
      mt: 2,
      input: [
        {
          key: "pinCode",
          title: "Postal/Zip Code",
          required: true,
          maxLength: 10,
        },
        {
          key: "countryCode",
          title: "Country",
          required: true,
          type: "select",
          placeholder: "Select Country",
          options: [
            { label: "India", value: "IN" },
            { label: "Canada", value: "CA" },
          ],
        },
      ],
    },
    {
      title: "",
      mt: 2,
      input: [
        {
          key: "stateName",
          required: true,
          title: "State/Province",
          type: "select",
          placeholder: "Select State/Province",
          dependent: "countryCode",
          options: {
            IN: [
              { label: "Punjab", value: "Punjab" },
              { label: "Haryana", value: "Haryana" },
            ],
            CA: [],
          },
        },
        {
          key: "cityName",
          required: true,
          title: "City/Town",
        },
      ],
    },
  ];
};

export const reviewPolicy = [
  "Review your trip details to make sure the dates and times are correct.",
  "Check spellings. Flight passenger names must exactly match government-issued photo ID.",
  "Name changes are not permitted once tickets are issued. Tickets are non-transferable and non-refundable.",
  "Total fares include all taxes and fees, except additional airline fees such as baggage & seat assignment may apply.",
  "Your credit card may be billed in multiple charges totaling the above amount.",
  "All other booking Rules & Restrictions",
];
