import { call, put, takeLatest } from "redux-saga/effects";
import {} from "../../interfaces";
import {
  CREATE_FLIGHT_ORDER,
  FETCH_DEAL_FLIGHTS,
  SEARCH_FLIGHTS,
} from "./types";
import {
  createOrder,
  fetchDealFlights,
  fetchFlightOffersByGet,
  fetchFlightOffersByPost,
} from "../../services";
import {
  setBookedFlight,
  setDealFlights,
  setFilteredFlights,
  setFlights,
  setSearchModel,
} from "./actions";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setCurrentRoute } from "../router";
import { handleFetchAmadeusToken, store } from "src/store";
import { filterListData } from "src/views/website/flightsList/constants";

function* handleFlightOffers({ payload }: { payload: any }): Generator<any> {
  const token = yield call(handleFetchAmadeusToken);

  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(
      fetchFlightOffersByPost as any,
      payload,
      token
    );
    const model = store?.getState()?.flights?.searchModel;
    if (!model?.filtering && !model?.localFiltering) {
      yield put(setFlights(responseData));
    } else {
      yield put(setFilteredFlights(responseData));
    }
    yield put(setSearchModel(payload));
    if (model?.filtering && model?.localFiltering) {
      const filteredItems = filterListData(responseData, {
        duration: model?.duration,
        outbound: model?.outbound,
        outboundTime: model?.outboundTime,
        minOutboundTime: payload?.minOutboundTime,
        layover: model?.layover,
      } as any);
      yield put(setFilteredFlights(filteredItems));
    }
    if (responseData?.meta?.count === 0) {
      yield put(setAlert({ text: "Flights not found", type: "warning" }));
    }
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

let pricedata = {};
function* handleDealFlight(payload: any, title): Generator<any> {
  const token = yield call(handleFetchAmadeusToken);
  const responseData: any = yield call(fetchDealFlights as any, payload, token);
  const finalData = {
    [title]: responseData,
  };
  const deals = store?.getState()?.flights?.deals;
  pricedata = { ...pricedata, ...deals, ...finalData };
  yield put(setDealFlights(pricedata));
}
function* handleDealFlights({ payload }: { payload: any }): Generator<any> {
  try {
    for (let i = 0; i <= 3; i++) {
      yield call(
        handleDealFlight,
        {
          ...payload,
          origin: payload?.originDestinations[i]?.origin,
          destination: payload?.originDestinations[i]?.destination,
        },
        payload?.originDestinations[i]?.origin +
          "-" +
          payload?.originDestinations[i]?.destination
      );
    }
  } catch (error) {}
  yield put(removeLoader());
}

function* handleFlightOrder({ payload }: { payload: any }): Generator<any> {
  try {
    const token = yield call(handleFetchAmadeusToken);
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(createOrder as any, payload, token);
    yield put(setBookedFlight(responseData));
    yield put(setCurrentRoute({ path: "/flight-booking-success" }));
  } catch (error) {}
  yield put(removeLoader());
}

export function* flightSaga() {
  yield takeLatest<any>(SEARCH_FLIGHTS, handleFlightOffers);
  yield takeLatest<any>(FETCH_DEAL_FLIGHTS, handleDealFlights);
  yield takeLatest<any>(CREATE_FLIGHT_ORDER, handleFlightOrder);
}
