import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  changePasswordProps,
  loginProps,
  resetPasswordProps,
  userValidationProps,
  verifyEmailActionProps,
} from "../../interfaces";
import {
  HANDLE_FETCH_AMADEUS_TOKEN,
  HANDLE_LOGIN,
  HANDLE_FORGOT_PASSWORD,
  HANDLE_RESET_PASSWORD,
  HANDLE_CHANGE_PASSWORD,
  HANDLE_USER_VALIDATION,
  HANDLE_USER_VERIFICATION_EMAIL_RESEND,
  REMOVE_AUTH_USER,
  HANDLE_USER_VERIFY_EMAIL,
} from "./types";
import {
  getAmadeusToken,
  changePassword,
  forgotPassword,
  login,
  logout,
  validateUser,
  resetPassword,
  resendUserVerificationEmail,
  verifyEmail,
} from "../../services";
import { setAuthUser } from "./actions";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setCurrentRoute } from "../router";
import { store } from "../";
import { differenceInSeconds } from "date-fns";

export function* handleFetchAmadeusToken(): Generator<any> {
  var token_time = store?.getState()?.auth?.token_time;
  var expires_in = store?.getState()?.auth?.expires_in;
  var token = store?.getState()?.auth?.access_token;
  const seconds = differenceInSeconds(new Date(), new Date(token_time));
  if (seconds < parseInt(expires_in)) {
    return token;
  }
  try {
    const responseData: any = yield call(getAmadeusToken);
    yield put(setAuthUser({ ...responseData, token_time: new Date() }));
    token = responseData?.access_token;
  } catch (error) {}
  return token;
}

function* handleLogin({ payload }: { payload: loginProps }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(login, payload);

    if (responseData?.emailVerified) {
      yield put(setAuthUser(responseData));
    } else {
      yield put(
        setAlert({ text: "Your Email is not yet verified", type: "error" })
      );
      yield put(setCurrentRoute({ path: "verifyemail" }));
    }
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLogout() {
  yield put(setLoader({ transparent: true }));
  try {
    yield call(logout);
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUserValidation({
  payload,
}: {
  payload: userValidationProps;
}): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(validateUser, payload);
    yield put(setAuthUser({ ...responseData, isLoggedIn: true }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUserVerifyEmail({
  payload,
}: {
  payload: verifyEmailActionProps;
}): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(verifyEmail, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    if (payload.mode === "verifyEmail") {
      yield put(setCurrentRoute({ path: "login" }));
    }
  } catch (error) {
    yield put(setCurrentRoute({ path: "login" }));
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUserVerificationEmailResend(): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(resendUserVerificationEmail);
    yield put(
      setAlert({
        text: "Verification email sent again successfully",
        type: "success",
      })
    );
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleForgotPassword({
  payload,
}: {
  payload: string;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(forgotPassword, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleResetPassword({
  payload,
}: {
  payload: resetPasswordProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(resetPassword, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    yield put(setCurrentRoute({ path: "/login" }));
  } catch (error) {
    yield put(
      setCurrentRoute({
        path: "/forgot-password",
      })
    );
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleChangePassword({
  payload,
}: {
  payload: changePasswordProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(changePassword, payload);
    window.history.back();
    yield put(setAlert({ text: responseData, type: "success" }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* authSaga() {
  yield takeLatest<any>(HANDLE_FETCH_AMADEUS_TOKEN, handleFetchAmadeusToken);
  yield takeLatest<any>(HANDLE_LOGIN, handleLogin);
  yield takeLatest<any>(
    HANDLE_USER_VERIFICATION_EMAIL_RESEND,
    handleUserVerificationEmailResend
  );
  yield takeLatest<any>(HANDLE_USER_VERIFY_EMAIL, handleUserVerifyEmail);
  yield takeLatest<any>(HANDLE_USER_VALIDATION, handleUserValidation);
  yield takeLatest<any>(HANDLE_FORGOT_PASSWORD, handleForgotPassword);
  yield takeLatest<any>(HANDLE_RESET_PASSWORD, handleResetPassword);
  yield takeLatest<any>(HANDLE_CHANGE_PASSWORD, handleChangePassword);
  yield takeLatest<any>(REMOVE_AUTH_USER, handleLogout);
}
