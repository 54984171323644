import { store } from "src/store";
import { Root } from "./mailDataProps";
import { travellersPriceDetailHandler } from "src/views/website/flightsList/constants";
import { getAirportName, getCanadaToCanada } from "src/views/website/helpers";
import moment from "moment";
import { addTax, formatCardNumber, formatExpiryDate } from "./handlers";

const travellerTitleKey = {
  adults: "Adult",
  children: "Children",
  infants: "Infant",
};

export const createMailTemplate = (travelers: any[], formData: Root) => {
  let current = "adults";
  let index = 1;
  const travelersTemplate = travelers?.map((item) => {
    let currentIndex = index;
    if (current == item?.type) {
      index++;
    } else {
      currentIndex = 1;
      index = 2;
    }
    return `<tr class="traveler">
    <th colspan="5"># ${travellerTitleKey[item?.type]} ${currentIndex}</th>
</tr>
<tr class="tagdata">
    <td>${item?.name?.firstName}</td>
    <td>${item?.name?.middleName}</td>
    <td>${item?.name?.lastName}</td>
    <td>${item?.dateOfBirth}</td>
    <td colspan="1">${item?.gender}</td>
</tr>`;
  });
  const flightData = formData?.data?.flightOffers[0];
  const model = store?.getState()?.flights?.searchModel;

  const travelersTitle = {
    ADULT: "Adults",
    CHILD: "Children",
    HELD_INFANT: "Infants",
  };

  const travelerCounts = {
    ADULT: model?.adults,
    CHILD: model?.children,
    HELD_INFANT: model?.infants,
  };
  const locations = store?.getState()?.flights?.list?.dictionaries?.locations;
  const airportCityDictionaries =
    store?.getState()?.locations?.cityAirportDictionaries;
  const booking = store?.getState()?.flights?.booking;
  const isCanadaToCanada = getCanadaToCanada(
    flightData?.itineraries[0]?.segments,
    locations
  );
  const travelerPricings = travellersPriceDetailHandler(
    booking,
    isCanadaToCanada
  );

  const travelerPricingTemplate = Object?.keys(travelerPricings)?.map(
    (item) => {
      return `<tr class="tagdata">
          <td>${travelersTitle[item]}</td>
          <td>${travelerPricings[item]?.base}</td>
          <td>${travelerPricings[item]?.fees}</td>
          <td colspan="2"><b>${travelerPricings[item]?.total}</b></td>
          </tr>`;
    }
  );

  let totalPricing = booking?.price;
  if (isCanadaToCanada) {
    totalPricing = addTax(totalPricing);
  }

  let t: any = totalPricing?.grandTotal;
  let b: any = totalPricing?.base;

  let taxFees = parseFloat(t) - parseFloat(b);

  const totalPricingTemplate = `<tr class="tagdata">
  <td><b>Total</b></td>
  <td><b>${totalPricing?.base}</b></td>
  <td><b>${taxFees?.toFixed(2)}</b></td>
  <td colspan="2"><b>${totalPricing?.total}</b></td>
  </tr>`;
  const billingDetail = formData?.billingDetailModel;

  const from = booking?.itineraries[0]?.segments[0];
  const to =
    booking?.itineraries[0]?.segments[
      booking?.itineraries[0]?.segments?.length - 1
    ];

  const body = `<html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
    <title>Mobile Responsive Email</title>
    
    <style type="text/css">
        body{
            font-family: 'Inter', sans-serif;
        }
    th, td, p, a {
            font-family: 'Inter', sans-serif;
            font-size:18px;
    }
    th {
        font-weight: 500;
        text-align: left;
        width: 20%;
    }
    td {
        font-weight: 300;
        font-size: 17px;
    }
    table {
        width: 1270px;
    }
    tr.titelbg th {
        padding: 10px 10px;
        background: #185791;
        color: #fff;
    }
    td, th {
        padding: 9px 9px;
    }
    
    td {
        border-bottom: 0;
    }
    tr.traveler th {
        border: none;
        background: #e8e8e8;
    }
    .logoplane span {
        display: flex;
        gap: 10px;
        align-content: center;
        align-items: center;
    }
    </style>
    
    </head>
    </body>
            <table style="margin: auto;border-spacing: 0px 1px;">
                <tr class="titelbg">
                    <th colspan="5">Booking Details:</th>
                </tr>
                <tr class="tagname1">
                    <th colspan="2">Departing from</th>
                    <th colspan="2">Arriving at</th>
                </tr>
                <tr class="tagdata1">
                    <td colspan="2">${
                      getAirportName(
                        airportCityDictionaries,
                        from?.departure?.iataCode
                      )?.fullName
                    }</td>
                    <td colspan="2">${
                      getAirportName(
                        airportCityDictionaries,
                        to?.arrival?.iataCode
                      )?.fullName
                    }</td>
                </tr>
                
                <tr class="tagdata1">
                    <td colspan="2">${moment(from?.departure?.at as any).format(
                      "MM/DD/YYYY"
                    )}</td>
                    <td colspan="2">${moment(to?.arrival?.at as any).format(
                      "MM/DD/YYYY"
                    )}</td>
                </tr>
                
                <tr class="tagdata1">
                    <td colspan="2">${moment(from?.departure?.at as any).format(
                      "HH:mm"
                    )}</td>
                    <td colspan="2">${moment(to?.arrival?.at as any).format(
                      "HH:mm"
                    )}</td>
                </tr>
            
    <!--Contact info -->
                <tr class="titelbg">
                    <th colspan="5">1. Contact Info</th>
                </tr>
                <tr class="tagname">
                    <th colspan="2">Name</th>
                    <th colspan="2">Email</th>
                </tr>
                <tr class="tagdata">
                    <td colspan="2">${travelers[0]?.name?.firstName} ${
    travelers[0]?.name?.middleName
  }  ${travelers[0]?.name?.lastName}</td>
                    <td colspan="2">${billingDetail?.email}</td>
                </tr>
                <tr class="tagname">			
                    <th colspan="2">Phone</th>
                    <th colspan="2">Address</th>
                </tr>
                
                <tr class="tagdata">
                    <td colspan="2">${billingDetail?.phoneNumber}</td>
                    <td colspan="2">${billingDetail?.address}, ${
    billingDetail?.cityName
  }, ${billingDetail?.stateName}, ${billingDetail?.countryCode}, ${
    billingDetail?.pinCode
  }</td>
                </tr>
    <!--End Contact info -->
                
        
    <!--Travellers info -->		
    
                <tr class="titelbg">
                    <th colspan="5">2. Travellers Info</th>
                </tr>
                <tr class="tagname">
                    <th colspan="1">First Name</th>
                    <th colspan="1">Middle Name</th>
                    <th colspan="1">Last Name</th>
                    <th colspan="1">Date of Birth</th>
                    <th colspan="1">Gender</th>
                </tr>
                ${travelersTemplate?.join("")}
    
    <!--Travellers info -->
        
    
    <!--Payment info -->
    
                <tr class="titelbg">
                    <th colspan="5">3. Payment Details</th>
                </tr>
                <tr class="tagname">
                    <th colspan="2">Card Number</th>
                    <th colspan="2">Name on card</th>
                </tr>
                <tr class="tagdata">
                    <td colspan="2">${formatCardNumber(
                      billingDetail?.cardNumber
                    )}</td>
                    <td colspan="2">${billingDetail?.nameOnCard}</td>
                </tr>
                <tr class="tagname">			
                    <th colspan="2">Date of Expiry</th>
                    <th colspan="2">CVV</th>
                </tr>
                
                <tr class="tagdata">
                    <td colspan="2">${formatExpiryDate(
                      billingDetail?.dateOfExpiry
                    )}</td>
                    <td colspan="2">${billingDetail?.cvv}</td>
                </tr>
    
    <!--Payment info -->
    
        
        
    <!--Payment info -->
    
                <tr class="titelbg">
                    <th colspan="5">4. Total Price</th>
                </tr>
                <tr class="tagname">
                    <th colspan="1">Traveler Type</th>
                    <th colspan="1">Base Fare</th>
                    <th colspan="1">Taxes & Fees</th>
                    <th colspan="2">Total</th>
                </tr>
                ${travelerPricingTemplate?.join("")}
                ${totalPricingTemplate}
    
    <!--Payment info -->
                
            </table>
            
            
    </body>
    </html>`;

  return body;
};
