import { Box, Container, Stack, alpha, lighten, useTheme } from "@mui/material";
import { connect } from "react-redux";
import {
  flightDataProps,
  flightSearchProps,
  reducerProps,
  routeProps,
} from "src/interfaces";
import { useEffect, useState } from "react";
import RenderDetail from "./flightDetail";
import RenderItem from "./flightItem";
import DetailForm from "./form";
import { setCurrentRoute } from "src/store";

const Component = ({
  booking,
  dictionaries,
  cityAirportDictionaries,
  model,
  setRoute,
}: {
  booking: flightDataProps;
  dictionaries: any;
  cityAirportDictionaries: any;
  model: flightSearchProps;
  setRoute: (payload: routeProps) => void;
}) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const bodyWidth = document.body.clientWidth;
  const [conntainerPadding, setContainerPadding] = useState({});
  useEffect(() => {
    if (!booking || Object?.keys(booking)?.length === 0) {
      setRoute({ path: "/" });
    }
    if (bodyWidth < 991) {
      setContainerPadding({ px: 0 });
    } else {
      setContainerPadding({});
    }
  }, [bodyWidth]);

  const [billingDetailModel, setBillingDetailModel] = useState<any>({});

  return (
    <>
      <Container maxWidth="lg" sx={{ ...conntainerPadding }}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", my: { md: 2, xs: 0 } }}
        >
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box
              sx={{
                mb: 0.5,
                pt: { md: 1, xs: 1 },
                pb: { md: 1, xs: 0.5 },
                borderBottom: booking ? "unset" : { md: "1px dashed gray" },
                boxShadow: booking
                  ? "unset"
                  : theme.palette.mode === "dark"
                  ? `0 1px 0 ${alpha(
                      lighten(theme.colors.primary.main, 0.7),
                      0.15
                    )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                  : `0px 2px 8px -3px ${alpha(
                      theme.colors.alpha.black[100],
                      0.2
                    )}, 0px 5px 22px -4px ${alpha(
                      theme.colors.alpha.black[100],
                      0.1
                    )}`,
                pl: { md: 2, xs: 0 },
              }}
            >
              <RenderItem
                flightData={booking}
                dictionaries={dictionaries}
                onShowDetailClick={() => setShowDetails(!showDetails)}
                showDetail={showDetails}
                oneway={model?.type === "oneway"}
                cityAirportDictionaries={cityAirportDictionaries}
              />
              {showDetails && (
                <Box
                  sx={{
                    transition: "all",
                    transitionDuration: 0.8,
                    pb: 3,
                    mt: 4,
                  }}
                >
                  <Stack
                    component="h3"
                    spacing={1.5}
                    sx={{
                      alignItems: "center",
                      ml: 1,
                      pb: 0.5,
                      borderBottom: `1px solid ${theme?.colors?.alpha?.gray[30]}`,
                    }}
                    direction="row"
                  >
                    <Box>Booking Details:</Box>
                  </Stack>
                  <RenderDetail
                    flightData={booking}
                    dictionaries={dictionaries}
                    oneway={model?.type === "oneway"}
                    travelClass={model?.travelClass}
                    cityAirportDictionaries={cityAirportDictionaries}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Stack>
        <Box sx={{ px: { md: 2.5, xs: 1.5 } }}>
          <DetailForm
            setBillingDetailModel={setBillingDetailModel}
            billingDetailModel={billingDetailModel}
          />
        </Box>
      </Container>
    </>
  );
};

const mapStateToProps = ({
  flights: { booking, list, searchModel },
  locations: { cityAirportDictionaries },
}: reducerProps) => ({
  model: searchModel,
  booking,
  dictionaries: list?.dictionaries,
  cityAirportDictionaries,
});

const mapDispatchToProps = (dispatch) => ({
  setRoute: (payload: any) => dispatch(setCurrentRoute(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
