import { fetch } from "./http";

const controller: string = "v1/reference-data/";

export const fetchAirportsCities = async (keyword = "", token: string) => {
  try {
    const response = await fetch.Get(
      controller +
        `locations?subType=CITY&keyword=${keyword}&page%5Blimit%5D=10&page%5Boffset%5D=0&sort=analytics.travelers.score&view=FULL`,
      token
    );
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 201) {
      const data = await response.json();
      throw new Error(data);
    } else if (response?.status != 429 && response?.status != 401) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAirportCityDetail = async (code: string, token:string) => {
  try {
    const response = await fetch.Get(controller + `locations/${code}`, token);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 201) {
      const data = await response.json();
      throw new Error(data);
    } else if (response?.status != 429 && response?.status != 401) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
